import { useAppTranslation } from '../../../general/providers/LanguageProvider'

/**
 * InitialDashboardScreen
 * 
 * @description
 * An initial child component for the GeneralDashboard, designed as a grid layout 
 * with two columns and two rows, each containing a card with a dark gray background 
 * and white text. This component serves as the default view upon loading the page.
 * 
 * @returns {React.FC} The InitialDashboardScreen functional component.
 */

const InitialDashboardScreen = () => {
    const { t } = useAppTranslation()
    return (
        <div className="grid grid-cols-2 grid-rows-2 gap-4 w-full right-0">
<h1 className='dash-h1'>{t('general:general_dashboard')}</h1>
        </div>
    )
}

export default InitialDashboardScreen
