import React from 'react'
import { 
  CustomDatePicker, 
  CustomFileInput, 
  CustomTextArea, 
  CustomTextInput 
} from '@generalComponents'
import { Spinner } from "flowbite-react"
import { StepperNavigationButtons } from '@generalComponents'
import { useStepTwo } from '@reportCentralJourneyHooks'
import { useReportJourneyContext } from '@reportCentralJourneyContexts'
import { Controller } from 'react-hook-form'

/**
 * StepTwo Component
 * 
 * This component forms the second step in the report submission process,
 * allowing users to enter report details and upload evidence files.
 * It utilizes custom components for inputs and file upload.
 *
 * @returns {JSX.Element} The StepTwo component.
 */
const StepTwo: React.FC = (): JSX.Element => {
  const { goToNextStep, goBackToFirstStep, authorId } = useReportJourneyContext()
  const {
    t, startDate, setStartDate, evidences, setEvidences, allowedFileTypes,
    maxFileSize, maxFileCount, control, handleSubmit, isLoading, onSubmit
  } = useStepTwo(authorId, goToNextStep, goBackToFirstStep)

  return (
    <>
      <p className='my-5 text-sm'>
        <i>
          {t('reportCentral:step_two_1st_paragraph')} <u>{t('general:exclusivily')}</u> {t('reportCentral:step_two_2nd_paragraph')}
        </i>
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full text-center fade-in">
        <CustomDatePicker
          id="report-reason"
          selected={startDate}
          onChange={(date) => date && setStartDate(date)}
          className="focus:ring-[#ff6160] focus:border-[#ff6160] block sm:text-sm border-0 rounded-md w-full bg-[#1a1a1c] mb-0 mt-3 p-[0px]"
          placeholder={t('reportCentral:event_date')}
        />
        <Controller
          name="names"
          control={control}
          render={({ field }) => (
            <CustomTextInput
              id="involved-name"
              placeholder={t('general:envolved_name')}
              {...field}
            />
          )}
        />
        <Controller
          name="reportMessage"
          control={control}
          rules={{ required: t('reportCentral:report_message_required') }}
          render={({ field }) => (
            <CustomTextArea
              id="report-message"
              placeholder={t('reportCentral:your_report')}
              {...field}
            />
          )}
        />
        <CustomFileInput
          evidences={evidences}
          setEvidences={setEvidences}
          allowedFileTypes={allowedFileTypes}
          maxFileSize={maxFileSize}
          maxFileCount={maxFileCount}
        />
        
        <StepperNavigationButtons
          isDisabled={isLoading}
          onNext={handleSubmit(onSubmit)}
          onBack={goBackToFirstStep}
          nextButtonText={isLoading ? <center><Spinner /></center> : t('reportCentral:submit_report')}
          backButtonText={t('general:back')}
          onMouseEnter={() => {}}  
          onMouseLeave={() => {}}  
          firstButtonClassName="continue-step-one"
          secondButtonClassName="continue-step-one"
        />
      </form>
    </>
  )
}

export default StepTwo
