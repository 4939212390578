import axios from 'axios'
import Gallery from '../Gallery'
import React, { useState, useEffect, useCallback } from 'react'
import { DrawerProps } from '@componentInterfaces'
import { formatDateOfString } from '../../applicationManagement/utils/functions/DateFunctions'
import { showErrorToast, showSuccessToast } from "@generalUtilsFunctions"
import { Report } from '../../../generalPanel/generalDashboard/reportCentral/interfaces/ReportDashboardInterfaces'
import { useAppTranslation } from '@generalProviders'
import { X } from 'lucide-react'

/**
 * A React functional component that represents a drawer.
 * It takes a `report` prop of type `Report` and a `onClose` callback function.
 * It also has a `title` prop of type `string`.
 * This component fetches the report details when the `report` prop changes and sets the `isOpen` state accordingly.
 * It also handles the closing of the drawer when the `onClose` callback is called.
 * @param {DrawerProps} props - The props for the drawer component.
 * @returns {JSX.Element} - The JSX for the drawer component.
 */
const Drawer: React.FC<DrawerProps> = ({ report, onClose, title }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [reportDetails, setReportDetails] = useState<Report | null>(null)
    const [assessmentNumber, setAssessmentNumber] = useState('')
    const [statusNumber, setStatusNumber] = useState('')
    const { t } = useAppTranslation()

    const fetchReportDetails = useCallback(async () => {
        if (report) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/report/${report.id}`)
                setReportDetails(response.data)
            } catch (error) {
                console.error('Erro obtendo dados da denúncia:', error)
            }
        }
    }, [report])

    useEffect(() => {
        if (report) {
            setIsOpen(true)
            fetchReportDetails()
        } else {
            setIsOpen(false)
        }
    }, [report, fetchReportDetails])

    const handleClose = () => {
        setIsOpen(false)
        onClose()
    }

    if (!isOpen) {
        return null
    }

    const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
            handleClose()
        }
    }

    const handleSendUpdate = async () => {
        try {
            const params = new URLSearchParams()

            if (!report || !report.id) {
                showErrorToast('ID da denúncia não disponível')
                return
            }
            params.append('id', report.id.toString())

            if (statusNumber) {
                params.append('statusNumber', statusNumber.toString())
            }

            if (assessmentNumber) {
                params.append('assessmentNumber', assessmentNumber.toString())
            }

            if (Array.from(params).length <= 1) {
                showErrorToast('Nada para atualizar')
                return
            }

            const url = `${process.env.REACT_APP_BACKEND_URL}/report/?${params.toString()}`

            await axios.patch(url)

            showSuccessToast('Denúncia atualizada com sucesso')
            handleClose()

        } catch (error) {
            showErrorToast('Erro ao atualizar denúncia')
        }
    }

    return (
        <div className="fixed inset-0 overflow-hidden bg-gray-900/55" onClick={handleClickOutside} style={{ zIndex: 9996 }}>
            <div className="absolute inset-0 overflow-hidden">
                <div className="absolute inset-y-0 right-0 flex max-w-full pl-10">
                    <div className="relative w-screen max-w-md">
                        <div className="h-full flex flex-col py-6 bg-[#323236] text-white shadow-xl overflow-y-scroll">
                            <div className="px-4 sm:px-6">
                                <div className="flex items-start justify-between">
                                    <h2 className="text-lg font-medium">{title}</h2>
                                    <div className="flex items-center ml-3 h-7">
                                        <button onClick={handleClose} className="text-gray-300 bg-gray-700 rounded-md hover:text-white">
                                            <X className="w-6 h-6" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="relative flex-1 px-4 mt-6 sm:px-6">
                                {reportDetails ? (
                                    <div className="w-full p-5 text-left items-left">
                                        <div className='mb-2'>Protocolo:
                                            <br />
                                            {reportDetails?.protocolNumber?.protocolId || null}
                                        </div>
                                        <div className='mb-2'>Relação com a Lenarge:
                                            <br />
                                            {reportDetails?.author?.authorType?.name || null}
                                        </div>
                                        <div className='mb-2'>Denúncia:
                                            <br />
                                            {reportDetails?.reportMessage || null}
                                        </div>
                                        {reportDetails.names && (
                                            <div className='mb-2'>Envolvidos:
                                                <br />
                                                {reportDetails?.names || null}
                                            </div>
                                        )}
                                        {reportDetails.evidences && (
                                            <div className='mb-2'>Evidências:
                                                <br />
                                                <Gallery evidences={reportDetails.evidences} />
                                            </div>
                                        )}
                                        <div className='mb-2'>Status:
                                            <br />
                                            {reportDetails?.status?.name || null}
                                        </div>
                                        <div className='mb-2'>Avaliação:
                                            <br />
                                            {reportDetails.assessment.name}
                                        </div>
                                        <div className='mb-2'>Ocorrido em:
                                            <br />
                                            {formatDateOfString(reportDetails.startDate)}
                                        </div>
                                        <br />
                                        <br />
                                        <label htmlFor="change-evaluation" className="block text-sm font-medium text-white">
                                            Mudar avaliação para:
                                        </label>
                                        <select id="change-evaluation" value={assessmentNumber} onChange={e => setAssessmentNumber(e.target.value)} className="block w-full py-2 pl-3 pr-10 mt-1 text-base text-white bg-gray-800 border-gray-600 rounded-md focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm">
                                            <option value="">Não mudar</option>
                                            <option value={4}>Procedente</option>
                                            <option value={2}>Improcedente</option>
                                            <option value={3}>Inconclusiva</option>
                                        </select>
                                        <label htmlFor="change-status" className="block mt-4 text-sm font-medium text-white">
                                            Mudar status para:
                                        </label>
                                        <select id="change-status" value={statusNumber} onChange={e => setStatusNumber(e.target.value)} className="block w-full py-2 pl-3 pr-10 mt-1 text-base text-white bg-gray-800 border-gray-600 rounded-md focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm">
                                            <option value="">Não mudar</option>
                                            <option value={2}>Em Análise</option>
                                            <option value={3}>Concluída</option>
                                        </select>
                                        <button onClick={handleSendUpdate} className="inline-flex justify-center w-full px-4 py-2 mt-4 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                            {t('general:update')}
                                        </button>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Drawer
