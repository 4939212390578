import React from 'react'
import '@css/warningText.css'

/**
 * WarningText Component
 * Displays a blinking text warning.
 *
 * @param {string} text - The warning text to display.
 * @returns {React.ReactElement} - The WarningText component.
 */
const WarningText: React.FC<{ text: string }> = ({ text }) => {
    return <div className="mt-2 warning-text">
        <strong>
            {text}
        </strong>
    </div>
}

export default WarningText
