import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const ServerError = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/')
    }, 7000)

    return () => clearTimeout(timer)
  }, [navigate])

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <img src="/path/to/your/500-image.png" alt="500 Server Error" className="mx-auto mb-4" />
        <h1 className="mb-2 text-4xl font-bold">Erro do Servidor</h1>
        <p className="text-lg">Você será redirecionado para a página inicial em 7 segundos.</p>
      </div>
    </div>
  )
}

export default ServerError
