import React, { useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Logo, CustomTextInput, CustomPasswordInput, CustomButton } from "@generalComponents"
import { LoginFormInputs } from "@componentInterfaces"
import { useForm, Controller } from 'react-hook-form'
import { useLoginFormHandlers } from '@componentHooks'
import { useAppTranslation } from '@generalProviders'
import "@locales"
import "react-toastify/dist/ReactToastify.css"

/**
 * LoginForm Component
 *
 * This component renders a form to input and validate a protocol number.
 * It uses react-hook-form for form management and validation. Upon successful submission
 * of a valid protocol number, it fetches the corresponding data from the backend.
 *
 * @returns {React.ReactElement} The rendered LoginForm component.
 */
const LoginForm: React.FC = () => {
  const { t } = useAppTranslation()
  const { handleSubmit, control } = useForm<LoginFormInputs>({
    defaultValues: {
      email: '', 
      password: ''
    }
  })
  const [loading, setLoading] = useState(false)
  const { onSubmit, showError } = useLoginFormHandlers()
  const provisoryLink = "#"

  const handleFormSubmit = useCallback(async (data: LoginFormInputs) => {
    setLoading(true)
    await onSubmit(data)
    setLoading(false)
  }, [onSubmit])

  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit, showError)} id="login-form">
        <center>
          <Logo id="login-form-logo" />
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block my-3 text-sm font-bold text-gray-200"
            >
              {t("general:intranet")}{" "} {t('lenarge')}
            </label>
            <div className="relative w-full mt-1 rounded-md shadow-sm">
              <Controller
                name="email"
                control={control}
                rules={{ required: t('formValidation:email_required') }}
                render={({ field }) => (
                  <CustomTextInput
                    id="email"
                    autoFocus
                    placeholder={t("general:insert_email")}
                    autoComplete="email"
                    {...field}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                rules={{ required: t('formValidation:password_required') }}
                render={({ field }) => (
                  <CustomPasswordInput
                    id="password"
                    placeholder={t("general:insert_password")}
                    autoComplete="current-password"
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <CustomButton
              id="intranet-button"
              type="submit"
              content={t("general:enter")}
              className="items-center w-full p-2 border border-[#ff6160] rounded-lg shadow hover:bg-[#ff6160] focus:outline-none focus:shadow-outline"
              loading={loading}
              disabled={loading}
            />
            <Link style={{ textDecoration: 'underline' }} to={provisoryLink}>Esqueci minha senha</Link>
          </div>
        </center>
      </form>
    </>
  )
}

export default LoginForm
