import React, { forwardRef } from 'react'
import { CustomInputProps } from '@componentInterfaces'

/**
 * CustomTextInput Component
 * A custom input component for text input.
 *
 * @param {CustomInputProps} props - The props for the component.
 * @returns {React.ReactElement} - The CustomTextInput component.
 */
const CustomTextInput = forwardRef<HTMLInputElement, CustomInputProps>((props, ref) => {
    const { id, value, onChange, placeholder, autoFocus, autoComplete, onKeyDown, ...rest } = props

    return (
        <input
            type="text"
            id={id}
            value={value}
            placeholder={placeholder}
            className="focus:ring-[#ff6160] focus:border-[#ff6160] block sm:text-sm border-0 rounded-md w-full bg-[#1a1a1c] mb-2 mt-2 custom-input"
            onChange={onChange}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            onKeyDown={onKeyDown}
            ref={ref}
            {...rest}
        />
    )
})

export default CustomTextInput
