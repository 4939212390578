import { useCallback, useRef, useState } from 'react'
import { useAppTranslation } from '@generalProviders'
import "@locales"

/**
 * Custom hook to manage the state and functionalities of CustomFileInput component.
 *
 * @param {React.Dispatch<React.SetStateAction<File[]>>} setEvidences - Function to update evidences state.
 * @param {File[]} evidences - Array of current evidences.
 * @param {string[]} allowedFileTypes - Array of allowed file types.
 * @param {number} maxFileSize - Maximum allowed file size in bytes.
 * @param {number} maxFileCount - Maximum number of files allowed.
 * @returns Object containing various state variables and handlers for the CustomFileInput component.
 */
export const useCustomFileInput = (
    setEvidences: React.Dispatch<React.SetStateAction<File[]>>, 
    evidences: File[],
    allowedFileTypes: string[],
    maxFileSize: number,
    maxFileCount: number
) => {
    const { t } = useAppTranslation() 
    const [isDragOver, setIsDragOver] = useState(false)
    const [notification, setNotification] = useState<string | null>(null)
    const fileInputRef = useRef<HTMLInputElement>(null)
    const notificationRef = useRef<HTMLDivElement>(null)

    /**
     * Displays a notification message and ensures it's visible to the user.
     * @param {string} message - The message to be displayed.
     */
    const displayNotification = useCallback((message: string) => {
        setNotification(message)
        setTimeout(() => setNotification(null), 4000)
        if (notificationRef.current) {
            notificationRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [])

    /**
     * Validates the array of new files based on size, type, and name.
     * @param {File[]} newFiles - Array of new files to be validated.
     * @returns {boolean} - True if all validations pass, false otherwise.
     */
    const validateFiles = useCallback((newFiles: File[]) => {
        if (evidences.length + newFiles.length > maxFileCount) {
            displayNotification(t('formValidation:input_file_max_file_quantity')+maxFileCount)
            return false
        }

        const totalSize = newFiles.reduce((acc, file) => acc + file.size, 0) + evidences.reduce((acc, file) => acc + file.size, 0)
        if (totalSize > maxFileSize) {
            displayNotification(t('formValidation:input_file_max_files_size')+ maxFileSize+'MB')
            return false
        }

        if (newFiles.some(file => !allowedFileTypes.includes(file.type.split('/')[1]))) {
            displayNotification(t('formValidation:input_file_not_allowed_extension'))
            return false
        }

        if (newFiles.some(file => evidences.some(e => e.name === file.name))) {
            displayNotification(t('formValidation:input_file_not_repeated_names'))
            return false
        }

        return true
    }, [evidences, allowedFileTypes, maxFileSize, maxFileCount, displayNotification, t])

    /**
     * Handles changes to the file input element.
     * @param {React.ChangeEvent<HTMLInputElement>} event - Change event from the input.
     */
    const handleFileInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files) {
                const newFiles = Array.from(event.target.files)
                if (validateFiles(newFiles)) {
                    setEvidences(prevEvidences => [...prevEvidences, ...newFiles] as File[])
                }
            }
        },
        [validateFiles, setEvidences]
    )

    /**
     * Handles file drop event.
     * @param {React.DragEvent<HTMLDivElement>} event - Drag event containing the dropped files.
     */
    const handleDrop = useCallback(
        (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault()
            setIsDragOver(false)
            const newFiles = Array.from(event.dataTransfer.files)
            if (validateFiles(newFiles)) {
                setEvidences(prevEvidences => [...prevEvidences, ...newFiles] as File[])
            }
        },
        [validateFiles, setEvidences]
    )

    /**
     * Handles the drag over event, updating the drag over state.
     * @param {React.DragEvent<HTMLDivElement>} event - The drag over event.
     */
    const handleDragOver = useCallback(
        (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault()
            setIsDragOver(true)
        },
        []
    )

    /**
     * Resets the drag over state when drag leaves the target area.
     */
    const handleDragLeave = useCallback(() => {
        setIsDragOver(false)
    }, [])

    /**
     * Handles the deletion of a file from the evidences.
     * @param {number} index - The index of the file to be deleted.
     */
    const handleDeleteFile = useCallback(
        (index: number) => {
            setEvidences(prevEvidences => prevEvidences.filter((_, i) => i !== index) as File[])
        },
        [setEvidences]
    )

    /**
     * Triggers the file input when the drag-and-drop area is clicked.
     */
    const triggerFileInput = () => {
        fileInputRef.current?.click()
    }

    return {
        isDragOver, setIsDragOver, notification, fileInputRef, 
        notificationRef, handleFileInputChange, handleDrop,
        handleDragOver, handleDragLeave, handleDeleteFile, triggerFileInput
    }
}
