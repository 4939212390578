//import axios from 'axios'
import React from 'react'
import { useAppTranslation } from '../../../general/providers/LanguageProvider'
import { useNavigate } from 'react-router-dom'

/**
 * LogoutButton Component.
 * This component renders a button that, when clicked, logs the user out of the application.
 * It uses the Axios library to send a POST request to the backend logout route.
 * In case of success, it redirects the user to the '/hr-panel' route.
 * If an error occurs during the logout process, it logs the error to the console.
 */
const LogoutButton: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useAppTranslation()

  /**
   * handleLogout function.
   * Sends a POST request to logout the user from the application.
   * On success, redirects to '/hr-panel'.
   * On failure, logs the error to the console.
   */
  const handleLogout = async () => {
    /*try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/logout`)
      navigate('/hr-panel')
    } catch (error) {
      console.error(t('general:logout_error'), error)
    }*/
    localStorage.removeItem('lng-bkfc-token')
    navigate('/login')
  }

  return <button onClick={handleLogout}>{t('general:logout')}</button>
}

export default LogoutButton
