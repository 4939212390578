import i18n from "i18next";
import Backend from "i18next-http-backend";
import general from "./pt/general.json";
import formValidation from "./pt/formValidation.json";
import LanguageDetector from "i18next-browser-languagedetector";
import lgpd from "./pt/lgpd.json";
import reportCentral from "./pt/reportCentral.json";
import { initReactI18next } from "react-i18next";

const defaultLanguage = process.env.REACT_APP_DEFAULT_LANG;
const fallbackLanguage = process.env.REACT_APP_FALLBACK_LANG;

/**
 * Initialize i18next with HTTP backend.
 * This configuration loads translation files dynamically from a server,
 * which is beneficial for handling large sets of translations or
 * updating translations without redeploying the app.
 */
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      pt: {
        general: general,
        formValidation: formValidation,
        reportCentral: reportCentral,
        lgpd: lgpd,
      },
    },
    lng: defaultLanguage,
    fallbackLng: fallbackLanguage,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
  });

export default i18n;
