import React, { createContext, useContext } from 'react'
import { useReportJourney } from '../hooks/useReportJourney'
import { ReportJourneyProviderProps } from '../interfaces/ReportJourneyInterfaces'


const ReportJourneyContext = createContext<ReturnType<typeof useReportJourney> | undefined>(undefined)

/**
 * ReportJourneyProvider Component
 * 
 * This component provides the context for the ReportJourney, sharing the state
 * and functions managed by the useReportJourney hook.
 *
 * @param {ReportJourneyProviderProps} props - The props for the provider component.
 * @returns {JSX.Element} The provider component.
 */
export const ReportJourneyProvider = ({ children }: ReportJourneyProviderProps): JSX.Element => {
    const journey = useReportJourney()
    return (
        <ReportJourneyContext.Provider value={journey}>
        {children}
        </ReportJourneyContext.Provider>
    )
}

/**
 * useReportJourneyContext Hook
 * 
 * This hook provides access to the ReportJourney context.
 *
 * @returns {ReturnType<typeof useReportJourney>} The context value.
 */
export const useReportJourneyContext = (): ReturnType<typeof useReportJourney> => {
    const context = useContext(ReportJourneyContext)
    if (context === undefined) {
        throw new Error('useReportJourneyContext must be used within a ReportJourneyProvider')
    }
    return context
}
