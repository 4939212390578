import CustomHeader from '../../general/components/CustomHeader'
import { InitialDashboardScreen } from './includes'
import Sidebar from '../../general/components/Sidebar'
import React, { useState, ReactNode } from 'react'
import { GeneralDashboardProvider } from './GeneralDashboardContext'
import { useAuth } from '@generalHooks'
import '@css/output.css'
import '@css/generalDashboard.css'

/**
 * GeneralDashboard Component
 *
 * @description
 * The GeneralDashboard component serves as the main layout structure for the dashboard interface.
 * It is designed to provide a cohesive layout, organizing the CustomHeader, Sidebar, and the main content area.
 * The component allows for dynamic rendering of child components within the main content area, 
 * based on user interactions with the Sidebar.
 * 
 * @returns {React.FC} 
 * The GeneralDashboard functional component, rendering the overall layout of the dashboard.
 * 
 * @example
 * 
 * <GeneralDashboard />
 * 
 * @note 
 * This component relies on Tailwind CSS for styling. Ensure that Tailwind CSS is correctly configured.
 *
 * @see 
 * CustomHeader, Sidebar, InitialDashboardScreen
 */
const GeneralDashboard: React.FC = () => {
  useAuth()
  const [currentChild, setCurrentChild] = useState<ReactNode>(<InitialDashboardScreen />)

  const handleSidebarClick = (childComponent: ReactNode) => {
    setCurrentChild(childComponent)
  }

  return (
    <GeneralDashboardProvider>
      <div className="flex min-h-screen bg-graybg-[#3b3b41]" id="dashboard">
        <div className="flex-none" id="side" style={{ width: '250px' }}>
          <Sidebar onSidebarClick={handleSidebarClick} />
        </div>
        <div className="flex-grow bg-gray-bg-[#3b3b41]" id="main"> 
          <CustomHeader />
          <main className="overflow-y-auto w-full bg-[#3b3b41]">
            <div className="max-w-full px-4 mx-auto sm:px-6 md:px-8">
              {currentChild}
            </div>
          </main>
        </div>
      </div>
    </GeneralDashboardProvider>
  )
}

export default GeneralDashboard
