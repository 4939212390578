import React from 'react'
import Logo from './layouts/Logo'
import LogoutButton from '../../generalPanel/generalDashboard/includes/LogoutButton'
/**
 * CustomHeader Component
 * 
 * @description
 * A custom header component for the application's dashboard. It displays the title
 * and includes a transparent background. Additionally, it has a logout button.
 * 
 * @returns {React.FC} The CustomHeader functional component.
 */

const CustomHeader = () => {
    return (
        <header className="mb-10 shadow-custom bg-[#323236] w-full border-b border-[#666]">
            <div className="w-full px-4 py-6 mx-auto sm:px-6 lg:px-8 header-panel">
                <div className="flex items-center justify-between w-full">
                    <h1 className="flex-none font-bold text-white">
                        <Logo 
                            id="header-logo"
                        />
                    </h1>
                    <LogoutButton />
                </div>
            </div>
        </header>
    )
}

export default CustomHeader


