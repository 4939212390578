// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.gallery-item {
  flex: 1 0 20%;
  position: relative;
}

.gallery-image,
.gallery-download {
  width: 80px;
  height: 80px;
  cursor: pointer;
}
.lightbox-image {
  width: auto;
  height: 100%;
  cursor: pointer;
  z-index: 9999 !important;
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9998 !important;
}

.lightbox-image {
  max-width: 80%;
  max-height: 80%;
}

.lightbox-close {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
  cursor: pointer;
}

.evidence-text {
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  margin-top: 5px;
  width: 80px;
}

.gallery-download {
  display: flex;
  align-items: center;
  gap: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/gallery.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,SAAS;AACX;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;AACA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,wBAAwB;AAC1B;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,oCAAoC;EACpC,wBAAwB;AAC1B;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,oCAAoC;EACpC,YAAY;EACZ,eAAe;EACf,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV","sourcesContent":[".gallery {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  gap: 10px;\r\n}\r\n\r\n.gallery-item {\r\n  flex: 1 0 20%;\r\n  position: relative;\r\n}\r\n\r\n.gallery-image,\r\n.gallery-download {\r\n  width: 80px;\r\n  height: 80px;\r\n  cursor: pointer;\r\n}\r\n.lightbox-image {\r\n  width: auto;\r\n  height: 100%;\r\n  cursor: pointer;\r\n  z-index: 9999 !important;\r\n}\r\n\r\n.lightbox {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  background-color: rgba(0, 0, 0, 0.8);\r\n  z-index: 9998 !important;\r\n}\r\n\r\n.lightbox-image {\r\n  max-width: 80%;\r\n  max-height: 80%;\r\n}\r\n\r\n.lightbox-close {\r\n  position: absolute;\r\n  top: 20px;\r\n  right: 20px;\r\n  padding: 10px;\r\n  cursor: pointer;\r\n}\r\n\r\n.evidence-text {\r\n  text-align: center;\r\n  color: #fff;\r\n  background-color: rgba(0, 0, 0, 0.5);\r\n  padding: 5px;\r\n  margin-top: 5px;\r\n  width: 80px;\r\n}\r\n\r\n.gallery-download {\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
