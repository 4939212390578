import React, { useState, useRef } from 'react'
import { AuthorTypeOption } from '@reportCentralComponents'
import { StepperNavigationButtons } from '@generalComponents'
import { useAppTranslation } from '@generalProviders'
import { useNavigate } from 'react-router-dom'
import { useReportJourneyContext } from '@reportCentralJourneyContexts'
import { WarningText } from '@generalUtils'

/**
 * StepOne Component
 * 
 * This component allows users to select their relationship with the organization.
 * It manages the state of the selected author type and handles navigation to the next step.
 * Users can choose from predefined author types, enhancing the user experience and data accuracy.
 *
 * @returns {JSX.Element} The StepOne component.
 */
const StepOne: React.FC = (): JSX.Element => {
  const { t } = useAppTranslation()
  const navigate = useNavigate()
  const { authorId, setAuthorType, goToNextStep } = useReportJourneyContext()
  const [selectedAuthorType, setSelectedAuthorType] = useState<number>(authorId)
  const continueButtonRef = useRef<HTMLButtonElement>(null)

  const handleSelectAuthorType = (type: number) => {
    setAuthorType(type)
    setSelectedAuthorType(type)
    continueButtonRef.current?.focus()
  }

  const authorOptions = [
    t('general:option_employee'),
    t('general:option_customer'),
    t('general:option_supplier'),
    t('general:option_witness'),
    t('general:deny_identification')
  ]

  const handleNextStep = () => {
    goToNextStep()
  }

  const handleBackStep = () => {
    navigate("/")
  }

  return (
    <div className="visible w-full text-center fade-in">
      <h3 className="m-2 text-lg">{t('reportCentral:step_one_1st_paragraph')}</h3>
      <span className="text-xs text-warn">{t('reportCentral:step_one_2nd_paragraph')}</span>

      <div className="text-center author-types" role="listbox">
        {authorOptions.map((type, index) => (
          <AuthorTypeOption
            key={index}
            type={type}
            index={index}
            isSelected={selectedAuthorType === index + 1}
            onSelect={handleSelectAuthorType}
          />
        ))}
        {selectedAuthorType === 0 ? (
          <WarningText text={t('general:select_above')} />
        ) : (
          <div className='my-2'><br />{" "}</div>
        )}
        <StepperNavigationButtons
          isDisabled={selectedAuthorType === 0}
          onNext={handleNextStep}
          onBack={handleBackStep}
          nextButtonText={t('general:continue')}
          backButtonText={t('general:back')}
          firstButtonClassName="continue-step-one"
        />
      </div>
      <p className='my-3 text-xs'>
        <i>
          {t('lgpd:report_central_lgpd_sufix_1st')} <a className="text-[#ff6160]" href="mailto:dpo@lenarge.com.br">dpo@lenarge.com.br</a> {t('general:or_by_the_phone')} <span className='text-[#ff6160]'> 31 9 7184-4240</span>.
        </i>
      </p>
      <p className='my-3 text-xs'>
        <i>
          A sua privacidade e segurança são de extrema importância para nós.
        </i>
      </p>
    </div>
  )
}

export default StepOne
