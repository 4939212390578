import React from 'react'
import { ModalProps } from '@componentInterfaces'
import { X } from 'lucide-react'

/**
 * A functional component that renders a modal.
 * @param children - The content to be displayed inside the modal.
 * @param showModal - A boolean value that determines whether the modal is visible or not.
 * @param setShowModal - A function that updates the `showModal` state.
 * @returns A JSX element representing a modal.
 */
const Modal: React.FC<ModalProps> = ({ children, isOpen, onClose, title }: ModalProps) => {
  return (
    <div
      className={`fixed top-0 left-0 w-full h-full z-50 ${!isOpen  ? 'hidden' : ''}`}
      style={{ background: 'rgba(0, 0, 0, 0.5)' }}
    >
      <div className="flex items-center justify-center h-full">
      
        <div
          className="bg-[#232326] rounded p-4 max-w-md mx-auto"
        >
            <div className='flex justify-end w-full'>
                <span className='w-full p-3 mt-2 text-left items-left justify-left'>
                    <h2 className='title-topic'>{title}</h2>
                </span>
                <button onClick={onClose}>
                    <X 
                        className="w-6 h-6 mt-2 text-white"
                        xlinkTitle={`t('general:close')`}
                    />
                </button>
            </div>
          {children}
          
        </div>
      </div>
    </div>
  )
}

export default Modal
