import { 
    ChangePassword, 
    GeneralDashboard 
} from '@generalPanel'
import { RouteInterface } from "@generalInterfaces"

/**
 * Defines an array of private user routes for the application.
 * Each route is represented as an object with a path and a component.
 *
 * @remarks
 * This array is used to define the routes for the private user section of the application.
 * The routes are defined using the `RouteInterface` interface, which is assumed to be defined elsewhere.
 *
 * @returns {Array<RouteInterface>} - An array of private user routes.
 */
const privateUserRoutes: Array<RouteInterface> = [
    {
        path: 'mudar-senha',
        component: ChangePassword,
    },
    {
        path: 'painel',
        component: GeneralDashboard,
    }

]

export default privateUserRoutes