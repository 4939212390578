import React from "react"
import { Footer } from '@generalComponents'
/**
 * CentralContainer Component.
 * 
 * This component acts as a container for central elements in a layout, providing 
 * consistent styling and structure. It receives children components to be rendered 
 * inside the container.
 * 
 * @param {React.ReactNode} children - Children components to be rendered inside the container.
 * @returns {React.FC} The CentralContainer component.
 */
const CentralContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div id="general" className="flex items-center justify-center min-h-screen">
      <div className="steps-container">
        {children}
      </div>
      <Footer />
    </div>
  )
}

export default CentralContainer
