import { BackOfficeInitialScreen } from 'domains/generalPanel'
import { 
    ReportCheck, 
    ReportChoose, 
    ReportJourney 
} from '@reportCentral'
import { RouteInterface } from "@generalInterfaces"


const  publicUserRoutes: Array<RouteInterface> = [
    {
        path: '/',
        component: ReportChoose,
    },
    {
        path: 'fazer-denuncia',
        component: ReportJourney
    },
    {
        path: 'acompanhar-denuncia',
        component: ReportCheck
    },
    {
        path: 'login',
        component: BackOfficeInitialScreen
    }
]

export default publicUserRoutes
