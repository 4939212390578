import { CustomInput } from '@generalComponents'
import DatePicker from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import React, { useState, useEffect } from 'react'
import { CustomDatePickerProps } from '@componentInterfaces'
import { registerLocale } from 'react-datepicker'
import { Locale, format } from 'date-fns'
import { showWarnToast } from '@generalUtilsFunctions'
import { useAppTranslation } from '@generalProviders'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'
/**
 * Custom DatePicker Component.
 *
 * Renders a date picker using the 'react-datepicker' library.
 * Allows users to select a date with custom styling. Prevents selection of future dates.
 * Displays the selected date in dd/MM/yyyy format.
 *
 * @param {CustomDatePickerProps} props - The properties of the component.
 * @returns {React.ReactElement} A customized DatePicker component.
 */
const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  id = 'custom-date-picker', 
  selected,
  onChange,
  className,
  placeholder
}) => {
  const [formattedDate, setFormattedDate] = useState('')
  const language = process.env.REACT_APP_LANGUAGE || 'pt-BR'
  registerLocale(language, ptBR as unknown as Locale)
  const { t } = useAppTranslation()

  useEffect(() => {
    if (selected) {
      setFormattedDate(format(selected, 'dd/MM/yyyy'))
    }
  }, [selected])


  const handleDateChange = (date: Date | null) => {
    if (date && date > new Date()) {
      
      showWarnToast(t('general:prevent_future_date'))
      return
    }
    if (date) {
      setFormattedDate(format(date, 'dd/MM/yyyy'))
      onChange(date)
    }
  }
  const handleLabelClick = () => {
    document.getElementById(id)?.click()
  }
  return (
    <div className={`${className} flex p-0`}>
      <label 
        htmlFor={id}
        className="flex-grow p-2 pl-3 text-left text-gray-500"
        onClick={handleLabelClick}
      >
        {placeholder}
      </label>
      <DatePicker
        locale={language}
        selected={selected}
        onChange={handleDateChange}
        customInput={<CustomInput id={id} value={formattedDate} />}
        className="flex-none w-3/5 p-2"
        id={id}
        dateFormat="dd/MM/yyyy"
      />
    </div>
  )
}

export default CustomDatePicker
