import React, { forwardRef } from 'react'
import { CustomInputProps } from '@componentInterfaces'

/**
 * Custom Input Component for DatePicker.
 *
 * A custom input styled as a clickable element for use with DatePicker component.
 * It displays the currently selected date or a placeholder text.
 *
 * @param {CustomInputProps} props - The properties of the component.
 * @returns {React.ReactElement} The custom input element for DatePicker.
 */
const CustomInput = forwardRef<HTMLDivElement, CustomInputProps>(
  ({ id, value, onClick }, ref) => (
    <div
      id={id}
      role="button"
      tabIndex={0}
      className="date-input focus:ring-[#ff6160] focus:border-[#ff6160] block sm:text-sm border-0 rounded-md w-full bg-[#1a1a1c] mb-0 mt-0 p-2 pr-6"
      onClick={onClick}
      ref={ref}
    >
      {value}
    </div>
  )
)

export default CustomInput
