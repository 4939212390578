import React from 'react'
import { LoadingScreenProps } from '@generalInterfaces'
import '@css/loading.css'


/**
 * LoadingScreen Component
 *
 * This component displays a loading indicator when `isLoading` is true.
 * Otherwise, it renders the `children` components.
 * 
 * @param {LoadingScreenProps} props - The component props
 * @returns {React.ReactElement} The LoadingScreen component
 */
const LoadingScreen: React.FC<LoadingScreenProps> = ({ children, isLoading }) => {
  if (isLoading) {
    return (
      <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-grey-500">
        <div className="loading-frame">
          <div className="center">
            <div className="dot-1"></div>
            <div className="dot-2"></div>
            <div className="dot-3"></div>
          </div>
        </div>
      </div>
    )
  }

  return <>{children}</>
}

export default LoadingScreen
