import { useState, useCallback, useEffect } from 'react'

/**
 * Custom React hook for managing loading state with a delay.
 *
 * @param initialState - The initial loading state. Default is `true`.
 * @param delay - The delay in milliseconds before setting loading state to `false`. Default is `2500`.
 *
 * @returns An object containing the loading state, and functions to start and stop the loading.
 *
 * @example
 * ```typescriptreact
 * import React from 'react'
 * import useLoading from './useLoading'
 *
 * const MyComponent = () => {
 *   const { isLoading, startLoading, stopLoading } = useLoading(true, 3000)
 *
 *   return (
 *     <div>
 *       {isLoading ? <p>Loading...</p> : <p>Loaded!</p>}
 *       <button onClick={startLoading}>Start Loading</button>
 *       <button onClick={stopLoading}>Stop Loading</button>
 *     </div>
 *   )
 * }
 * ```
 */
const useLoading = (initialState: boolean = true, delay: number = 2500) => {
    const [isLoading, setIsLoading] = useState(initialState)

    useEffect(() => {
        if (initialState) {
            const timer = setTimeout(() => {
                setIsLoading(false)
            }, delay)

            return () => clearTimeout(timer)
        }
    }, [initialState, delay])

    const startLoading = useCallback(() => {
        setIsLoading(true)
    }, [])

    const stopLoading = useCallback(() => {
        setIsLoading(false)
    }, [])

    return { isLoading, startLoading, stopLoading }
}

export default useLoading