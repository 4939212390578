import axios from 'axios'
import { createContext, useState } from 'react'
import { showErrorToast } from '@generalUtilsFunctions'
import { useAppTranslation } from '@generalProviders'

/**
 * ReportChannelContext is a React context that provides the functionality to fetch and display report data.
 * It is used to manage the state of report data and the visibility of the report data.
 */
export const ReportChannelContext = createContext({
  fetchReport: (protocolNumber: string) => Promise.resolve(),
  reportData: null,
  showReportData: false,
})

/**
 * ReportChannelProvider is a React context provider that manages the state of report data and the visibility of the report data.
 * It provides the functionality to fetch and display report data.
 *
 * @param {React.ReactNode} children - The children components that will be wrapped by the provider.
 *
 * @returns {JSX.Element} - The provider element that wraps the children components and provides the report data and visibility state.
 */
export const ReportChannelProvider = ({ children }: { children: React.ReactNode }) => {
  const [reportData, setReportData] = useState(null)
  const [showReportData, setShowReportData] = useState(false)
  const { t } = useAppTranslation()

  /**
   * Fetches report data from the backend API using Axios.
   *
   * @param {string} protocolNumber - The protocol number for which the report data needs to be fetched.
   *
   * @returns {Promise<void>} - A promise that resolves when the report data is fetched successfully.
   * @throws {Error} - If an error occurs during the fetch operation.
   */
  const fetchReport = async (protocolNumber: string) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/report-protocol/${protocolNumber}`)
      setReportData(response.data)
      setShowReportData(true)
    } catch (error) {
      setShowReportData(false)
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 500) {
          showErrorToast(t('general:error_unexpected'))
        } else if (error.response.status === 404) {
          showErrorToast(t('reportCentral:protocol_not_found'))
        }
      } else {
        showErrorToast(t('general:error'))
      }
    }
  }

  return (
    <ReportChannelContext.Provider value={{ fetchReport, reportData, showReportData }}>
      {children}
    </ReportChannelContext.Provider>
  )
}
