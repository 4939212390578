// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 415px) {
  .author-type-text {
    font-size: 11px;
  }

  .step-topic {
    max-width: 285px !important;
    width: 285px !important;
  }
  .step-line {
    max-width: 285px !important;
    width: 285px !important;
    margin: 0px;
  }
  .step-line-wrapper {
    margin-bottom: 20px;
  }
}

@media (max-width: 520px) {
  .step-sequency {
    text-align: right;
  }
  .step-button-responsive {
    margin: 0px;
    margin-top: 10px;
  }
  .step-line-wrapper {
    align-items: center;
  }
  .step-line {
    width: 100%;
    max-width: 100%;
  }
  .react-datepicker {
    margin-left: -120px;
  }
}

@media (min-width: 421px) and (max-width: 768px) {
  .step-topic {
    min-width: 155px;
    font-size: 14px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/reportJourney.css"],"names":[],"mappings":"AAAA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,2BAA2B;IAC3B,uBAAuB;EACzB;EACA;IACE,2BAA2B;IAC3B,uBAAuB;IACvB,WAAW;EACb;EACA;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;EACA;IACE,WAAW;IACX,gBAAgB;EAClB;EACA;IACE,mBAAmB;EACrB;EACA;IACE,WAAW;IACX,eAAe;EACjB;EACA;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,gBAAgB;IAChB,eAAe;EACjB;AACF","sourcesContent":["@media (max-width: 415px) {\r\n  .author-type-text {\r\n    font-size: 11px;\r\n  }\r\n\r\n  .step-topic {\r\n    max-width: 285px !important;\r\n    width: 285px !important;\r\n  }\r\n  .step-line {\r\n    max-width: 285px !important;\r\n    width: 285px !important;\r\n    margin: 0px;\r\n  }\r\n  .step-line-wrapper {\r\n    margin-bottom: 20px;\r\n  }\r\n}\r\n\r\n@media (max-width: 520px) {\r\n  .step-sequency {\r\n    text-align: right;\r\n  }\r\n  .step-button-responsive {\r\n    margin: 0px;\r\n    margin-top: 10px;\r\n  }\r\n  .step-line-wrapper {\r\n    align-items: center;\r\n  }\r\n  .step-line {\r\n    width: 100%;\r\n    max-width: 100%;\r\n  }\r\n  .react-datepicker {\r\n    margin-left: -120px;\r\n  }\r\n}\r\n\r\n@media (min-width: 421px) and (max-width: 768px) {\r\n  .step-topic {\r\n    min-width: 155px;\r\n    font-size: 14px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
