import React from "react"
import { useNavigate } from "react-router-dom"
import { Image } from '@generalComponents'
import { ImageButtonProps } from "@componentInterfaces"
/**
 * ImageButton Component.
 *
 * A reusable button component for the ReportDecision component.
 * It displays an image and text, and navigates to a specified route when clicked.
 *
 * @param {object} props - Contains properties for navigation, button text, and image source.
 * @returns {React.FC} A functional component for a report option button.
 */
const ImageButton: React.FC<ImageButtonProps> = ({ 
  id,
  imageId,
  navigateTo, 
  buttonText, 
  imageSrc 
}) => {
  const navigate = useNavigate()

  return (
    <div
      className="flex items-center max-w-xs p-0 mx-auto mt-2 border border-[#d10000] rounded-lg shadow option px- bg-gradient-to-r from-white to-[#d10000] hover:bg-white dark:bg-gray-800 dark:border-gray-700 from-10% dark:hover:bg-gray-700 hover:text-gray-900"
      onClick={() => navigate(navigateTo)}
      id={id}
    >
      <Image
        id={imageId}
        src={imageSrc}
        alt={buttonText}
        className="visible w-10 h-10 mr-2 round-left fade-in"
      />
      <h5 className="w-full pr-3 text-sm font-bold tracking-tight text-right">{buttonText}</h5>
    </div>
  )
}

export default ImageButton
