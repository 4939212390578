import axios from 'axios'
import { ChangePasswordInputs } from 'domains/generalPanel/interfaces/GeneralPanelInterfaces'
import { showErrorToast as rawShowErrorToast, showSuccessToast as rawShowSuccessToast } from '@generalUtilsFunctions'
import { useAppTranslation } from '@generalProviders'
import { useForm, SubmitHandler, FieldErrors } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import '@locales'

/**
 * Hook to handle the change password logic.
 *
 * @returns {object} - Contains methods and states for change password functionality.
 */
export const useChangePassword = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const email = location.state?.email
    const { t } = useAppTranslation()
    const [loading, setLoading] = useState(false)

    const showErrorToast = useCallback((message: string) => {
        rawShowErrorToast(message)
    }, [])

    const showSuccessToast = useCallback((message: string) => {
        rawShowSuccessToast(message)
    }, [])

    const { register, handleSubmit, formState: { errors }, watch, setError } = useForm<ChangePasswordInputs>({
        defaultValues: {
            newPassword: '',
            confirmPassword: ''
        }
    })

    useEffect(() => {
        if (!email) {
            navigate('/painel')
        }
    }, [email, navigate])

    const onSubmit: SubmitHandler<ChangePasswordInputs> = useCallback(async (data) => {
        setLoading(true)
        if (data.newPassword !== data.confirmPassword) {
            const errorMessage = t('formValidation:change_pass_match')
            setError('confirmPassword', { message: errorMessage })
            showErrorToast(errorMessage)
            setLoading(false)
            return
        }

        if (data.newPassword === 'mudar@123' || data.newPassword === 'Mudar@123') {
            const errorMessage = t('formValidation:change_pass_common')
            setError('newPassword', { message: errorMessage })
            showErrorToast(errorMessage)
            setLoading(false)
            return
        }

        try {
            const response = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/change-pass`, {
                email,
                password: data.newPassword
            })
            if (response.status === 200) {
                showSuccessToast(t('formValidation:change_pass_success'))
                const token = response.data.data.user_token.token
                localStorage.setItem('lng-bkfc-token', token)
                navigate('/painel')
            }
        } catch (error) {
            showErrorToast(t('formValidation:change_pass_error'))
        } finally {
            setLoading(false)
        }
    }, [email, navigate, setError, t, showErrorToast, showSuccessToast])

    const handleErrors = (errors: FieldErrors<ChangePasswordInputs>) => {
        (Object.keys(errors) as Array<keyof ChangePasswordInputs>).forEach((error) => {
            const message = errors[error]?.message as string
            if (message) {
                showErrorToast(message)
            }
        })
    }

    return {
        register,
        handleSubmit: handleSubmit(onSubmit, handleErrors),
        errors,
        watch,
        loading
    }
}
