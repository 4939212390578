import React, { createContext, useContext, ReactNode, useMemo } from 'react'
import { DomainAccessContextProps } from '@generalInterfaces'
import { useLocation, Navigate } from 'react-router-dom'

const DomainAccessContext = createContext<DomainAccessContextProps | undefined>(undefined)

/**
 * DomainAccessProvider Component
 *
 * @description
 * Provides the domain context based on the hostname of the window location.
 * Determines if the current domain is Report Central or Intranet.
 *
 * @param {ReactNode} children - The children components to be wrapped by the provider.
 * @returns {React.ReactElement} The provider component with domain context.
 */
const DomainAccessProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const hostname = window.location.hostname

    const isReportCentralDomain = useMemo(
        () =>
        hostname === process.env.REACT_APP_REPORT_CENTRAL_HREF ||
        hostname === process.env.REACT_APP_REPORT_CENTRAL_LOCALHREF,
        [hostname]
    )

    const isIntranetDomain = useMemo(
        () =>
        hostname === process.env.REACT_APP_INTRANET_HREF ||
        hostname === process.env.REACT_APP_INTRANET_LOCALHREF,
        [hostname]
    )

    const value = useMemo(
        () => ({ isReportCentralDomain, isIntranetDomain }),
        [isReportCentralDomain, isIntranetDomain]
    )

    return <DomainAccessContext.Provider value={value}>{children}</DomainAccessContext.Provider>
}

/**
 * useDomainAccess Hook
 *
 * @description
 * Custom hook to use the domain context.
 *
 * @returns {DomainAccessContextProps} The domain context.
 */
const useDomainAccess = (): DomainAccessContextProps => {
    const context = useContext(DomainAccessContext)
    if (context === undefined) {
        throw new Error('useDomainAccess must be used within a DomainAccessProvider')
    }
    return context
}

/**
 * RedirectBasedOnDomain Component
 *
 * @description
 * Redirects based on the domain and the current route.
 *
 * @param {ReactNode} children - The children components to be wrapped by the redirection logic.
 * @returns {React.ReactElement} The redirection logic based on the domain and route.
 */
const RedirectBasedOnDomain: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { isReportCentralDomain, isIntranetDomain } = useDomainAccess()
    const location = useLocation()

    if (isReportCentralDomain && location.pathname === '/login') {
        return <Navigate to="/404" />
    }

    if (
        isIntranetDomain &&
        (location.pathname === '/fazer-denuncia' || location.pathname === '/acompanhar-denuncia')
    ) {
        return <Navigate to="/404" />
    }

    if (isIntranetDomain && location.pathname === '/') {
        return <Navigate to="/login" />
    }

    return <>{children}</>
}

export { DomainAccessProvider, RedirectBasedOnDomain, useDomainAccess }
