import { useAppTranslation } from '@generalProviders'

/**
 * A functional component that renders the LGPD content.
 * It uses the `useAppTranslation` hook to access the translation function.
 * The component displays a div with maxHeight of 80vh and overflowY auto.
 * It contains several sections with translated text and formatting.
 *
 * @returns {React.FC} - A functional component that renders the LGPD content.
 */
const LGPDContent = () => {
    const { t } = useAppTranslation()

    return (
        <div style={{maxHeight: '80vh', overflowY: 'auto' }}>
            <hr />
            <h2 className='text-2xl mt-5 text-[#ff6160] mb-2'>{ t('lgpd:wich_data_we_collect')}</h2>
            <p>{ t('lgpd:lgpd_content_1st_paragraph')} <u>{t('general:none')}</u> {t('lgpd:lgpd_content_2nd_paragraph')}</p>
            <h2 className='text-2xl mt-5 text-[#ff6160] mb-2'>{ t('lgpd:data_for_where_and_how')}</h2>
            <p>{t('lgpd:lgpd_content_3rd_paragraph')}</p>
            <h2 className='text-2xl mt-5 text-[#ff6160] mb-2'>{ t('lgpd:data_for_what')}</h2>
            <p>{t('lgpd:lgpd_content_4th_paragraph')} <i>{ t('general:exclusivily')}</i> { t('lgpd:lgpd_content_5th_paragraph')}</p>
            <h2 className='text-2xl mt-5 text-[#ff6160] mb-2'>{ t('lgpd:data_for_which_time')}</h2>
            <p>{ t('lgpd:lgpd_content_6th_paragraph')}</p>
        </div>
    )

}

export default LGPDContent