import React, { useRef, useEffect, useCallback } from 'react'
import { formatDate, leftZeros } from '@generalUtilsFunctions'
import { motion } from 'framer-motion'
import { ReportDataDisplayProps } from '@reportCentralChannelInterfaces'
import { useAppTranslation } from '@generalProviders'
import useReportChannel from '../../hooks/useReportChannel'
import '@locales'

/**
 * ReportDataDisplay Component
 *
 * Renders the details of a report in a styled div with a toggle-down animation.
 * Displays report information including date, status, assessment, and message.
 *
 * @param {ReportDataDisplayProps} props - Props including report data and visibility.
 * @returns {React.ReactElement} The rendered ReportDataDisplay component.
 */
const ReportDataDisplay: React.FC<ReportDataDisplayProps> = ({ data, isVisible }) => {
  const { t } = useAppTranslation()
  const reportDisplayRef = useRef<HTMLDivElement>(null)
  const { getStatusStyle } = useReportChannel()

  /**
   * Safely retrieves the data from the report object for a given key.
   * If the key does not exist in the report object or its value is `undefined`,
   * it returns a default message "Nenhum Registro".
   *
   * @param {any} key - The key of the data to retrieve from the report object.
   * @returns {any} The data value for the given key, or "Nenhum Registro" if it does not exist or is `undefined`.
   */
  const safelyGetData = useCallback((key: any) => {
    return data && data[key] ? data[key] : t('general:none_registers')
  }, [data, t])

  const variants = {
    visible: { opacity: 1, height: 'auto' },
    hidden: { opacity: 0, height: 10 },
  }

  useEffect(() => {
    if (!isVisible) return

    const scrollContent = () => {
      if (reportDisplayRef.current) {
        reportDisplayRef.current.scrollBy({ top: 1, behavior: 'smooth' })
      }
    }

    const scrollInterval = setInterval(scrollContent, 30)
    return () => clearInterval(scrollInterval)
  }, [isVisible])

  return (
    <motion.div
      id="report-container"
      className="report-data-display bg-[#424242] rounded-lg shadow mt-6 p-4"
      initial="hidden"
      animate={isVisible ? "visible" : "hidden"}
      exit="show"
      variants={variants}
      transition={{ duration: 0.1 }}
      ref={reportDisplayRef}
    >
      <div className="space-y-4 text-white">
        <div className="report-metadata">
          <span className="text-[#ff6160] none">
            {safelyGetData("protocol")}
          </span>
        </div>
        <hr />
        <div className="report-metadata">
          <span>
            {t('general:reported_on')}: {formatDate(safelyGetData("createdAt"))}
          </span>
        </div>
        <div className="report-metadata">
          {t('reportCentral:report_relation')}: <i>{safelyGetData('author').authorType.name}</i>
        </div>
        <div className="report-metadata">
          {t('reportCentral:event_date')}: {formatDate(safelyGetData("startDate"))}
        </div>
        <div className="report-metadata">
          {t('general:status')}:
          <span className={`ml-4 p-2 ${getStatusStyle(safelyGetData('status').name)}`}>            
            {safelyGetData('status').name}
          </span>
        </div>
        {data.names === true && (
          <div className="report-metadata">
            <i>
              {t('reportCentral:report_has_envolved')}
            </i>
          </div>
        )}
        {data.evidencesQty !== 0 && (
          <div className="report-metadata">
            <span>
              {t('general:evidences_paragraph')}
              <strong className='text-[#ff6160]'>
                {leftZeros(data.evidencesQty, 4)}
              </strong>
              {t('general:evidences_paragraph_part_two')}
            </span>
          </div>
        )}
      </div>
    </motion.div>
  )
}

export default ReportDataDisplay
