import React from 'react'
import { currentYear } from '../../applicationManagement/utils/functions/DateFunctions'
import { Link } from 'react-router-dom'

/**
 * A functional component representing the footer of the application.
 * Displays the current year and a link to the company's website.
 *
 * @returns {React.FC} - A functional component for the footer.
 */
const Footer: React.FC = () => {
  return (
    /**
     * The main container for the footer content.
     */
    <div className='content-footer'>
      <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
        © {currentYear()}{" "} - {" "}
        
        <Link 
          to={{ pathname: "https://lenarge.com.br/" }} 
          target="_blank" 
          rel="noreferrer" 
          className="hover:underline"
        >
          Lenarge Transportes e Serviços
        </Link>
        .
      </span>
    </div>
  )
}

export default Footer

/*
*TO DO 
* TENANCY
*/