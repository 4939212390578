import React from "react"
import LogoLenarge from "@img/LogoLenarge.png"
import { BasicDOMProps } from "@generalInterfaces"
/**
 * A functional component that renders the Lenarge logo.
 *
 * @returns {React.ReactElement} - The rendered logo component.
 */
const Logo: React.FC<BasicDOMProps> = ({id, className}) => {
  /**
   * Renders the center-aligned image of the Lenarge logo.
   *
   * @returns {React.ReactElement} - The rendered image component.
   */
  return (
    <center>
      <img
        id={id}
        src={LogoLenarge}
        className={`visible sessent-p logo-img fade-in" ${className}`}
        alt="logo, lenarge, lenarge transportes"
      />
    </center>
  )
}

export default Logo

/**
 * TO DO
 * TENANCY
 */