export const currentMonth = () => {
  const today = new Date()
  return today.getMonth() + 1
}

export const currentYear = () => {
  const today = new Date()
  return today.getFullYear()
}

export const todayDate = () => {
  const today = new Date()
  return today.getDate()
}

export const currentDate = () => {
  return `${todayDate()}/${currentMonth()}/${currentYear()}`
}

export const formatDate = (timestampStr: any) => {
  const date = new Date(timestampStr)
  const day = String(date.getDate()).padStart(2, "0")
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const year = date.getFullYear()

  return `${day}/${month}/${year}`
}


export const formatDateOfString = (dateString: string) => {
  const date = new Date(dateString)
  return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`
}