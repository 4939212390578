import axios from 'axios'
import { useState, useCallback } from 'react'
import { CreateReportRequestProps } from '../interfaces/ReportJourneyInterfaces'

/**
 * useReportJourney Hook
 * 
 * This hook manages the state and navigation logic for the ReportJourney.
 * It includes specific states and functions for the report submission process including
 * file uploads and form data management.
 *
 * @returns {Object} The state and functions for managing the report journey.
 */
export const useReportJourney = () => {
    const [currentStep, setCurrentStep] = useState(1)
    const [authorId, setAuthorType] = useState<number>(0)
    const [CreateReportRequestProps, setCreateReportRequestProps] = useState<CreateReportRequestProps | null>(null)
    const [name, setName] = useState("")
    const [report, setReport] = useState("")
    const [evidences, setEvidences] = useState<File[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [protocolNumber, setProtocolNumber] = useState<number>(0)

  const goToNextStep = useCallback((newProps?: Partial<CreateReportRequestProps>) => {
        if (newProps?.protocolNumber) {
        setProtocolNumber(parseInt(newProps.protocolNumber)) 
        }
        const updatedProps: CreateReportRequestProps = {
        ...CreateReportRequestProps, 
        ...newProps, 
        authorId,
        reportMessage: newProps?.reportMessage ?? CreateReportRequestProps?.reportMessage ?? ''
        }
        setCreateReportRequestProps(updatedProps)
        setCurrentStep(currentStep + 1)
    }, [authorId, currentStep, CreateReportRequestProps])

    const goBackToFirstStep = useCallback(() => {
        setCurrentStep(1)
    }, [])

    const goBackToStepTwo = useCallback(() => {
        setCurrentStep(2)
    }, [])

    const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        const files = Array.from(event.dataTransfer.files)
        setEvidences(prevEvidences => [...prevEvidences, ...files])
    }, [])

    const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
        const filesArray = Array.from(event.target.files)
        setEvidences(prevEvidences => [...prevEvidences, ...filesArray])
        }
    }, [])

    const handleDeleteFile = useCallback((index: number) => {
        setEvidences(prevEvidences => prevEvidences.filter((_, i) => i !== index))
    }, [])

    const handleSubmit = useCallback(async () => {
        setIsLoading(true)
        const formData = new FormData()
        formData.append("authorId", String(authorId))
        formData.append("name", name || "")
        formData.append("reportMessage", report)
        evidences.forEach(file => formData.append("evidences", file))

        try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/report`, formData)

        if (response.status === 200) {
            // Handle successful response
        }
        } catch (error) {
        console.error("Error while sending data", error)
        } finally {
        setIsLoading(false)
        }
    }, [authorId, name, report, evidences])

    return {
        currentStep,
        setCurrentStep,
        authorId,
        setAuthorType,
        CreateReportRequestProps,
        goToNextStep,
        goBackToFirstStep,
        goBackToStepTwo,
        protocolNumber,
        name,
        setName,
        report,
        setReport,
        evidences,
        setEvidences,
        isLoading,
        handleSubmit,
        handleDrop,
        handleFileChange,
        handleDeleteFile
    }
}
