import React, { useState } from 'react'
import { Clipboard } from 'lucide-react'
import { CopyToClipboardProps } from '@layoutFunctionalInterfaces'
import { showInfoToast } from '@generalUtilsFunctions'
import { useAppTranslation } from '@generalProviders'
import '@locales'

/**
 * CopyToClipboardButton Component
 * This component provides a button that, when clicked, copies the content of a specified element to the clipboard.
 * It shows a tooltip when hovered over and displays a success toast message when the content is successfully copied.
 *
 * @param {CopyToClipboardProps} props - The properties of the component.
 * @param {string} props.targetId - The ID of the element whose content is to be copied.
 */
const CopyToClipboardButton: React.FC<CopyToClipboardProps> = ({
  targetId,
  id,
  idSufix = false
}) => {
  const { t } = useAppTranslation()
  const [showTooltip, setShowTooltip] = useState(false)

  /**
   * Copies the content of the target element to the clipboard and shows a success toast.
   */
  const copyToClipboard = () => {
    const contentToCopy = document.getElementById(targetId)?.textContent
    if (contentToCopy) {
      navigator.clipboard?.writeText(contentToCopy)
      setShowTooltip(false)
      showInfoToast(t('general:copy_to_clipboard_confirmation'))
    }
  }

  return (
    <div className="relative inline-block" id={`${id}-${idSufix}`}>
      <button
        onClick={copyToClipboard}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        className="p-2 rounded-full cursor-pointer hover:bg-gray-600"
        aria-label={t('general:copy_to_clipboard')}
        data-tooltip-target="tooltip-default"
      >
        <Clipboard className="w-6 h-6 text-white" />
      </button>
      {showTooltip && (
        <div
          id={`tooltip-default-${idSufix}`}
          role="tooltip"
          className="absolute z-10 visible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-100 tooltip dark:bg-gray-200"
        >
         {t('general:copy_to_clipboard')}
          <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
      )}
    </div>
  )
}

export default CopyToClipboardButton
