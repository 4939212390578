import { Logo, Modal, CustomButton, CustomTextInput } from '@generalComponents'
import { ReportDataDisplay } from '@reportCentralComponents'
import { useReportCheckForm } from '@reportCentralChannelHooks'
import 'react-toastify/dist/ReactToastify.css'
import '@locales'

/**
 * ReportCheckForm Component.
 * 
 * Renders a form for entering and validating a protocol number. On successful submission, 
 * it fetches the corresponding report data using the ReportChannelContext. The report data is then 
 * displayed using the ReportDataDisplay component.
 * 
 * @returns {React.ReactElement} - The rendered ReportCheckForm component.
 */
const ReportCheckForm = () => {
  const {
    t,
    register,
    handleSubmit,
    onSubmit,
    isSubmitting,
    navigate,
    onlyNumeric,
    showReportData,
    reportData,
    isModalOpen,
    closeModal
  } = useReportCheckForm()

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <center>
          <Logo id="report-check-form-logo" />
          <div className="mb-4">
            <label htmlFor="protocolNumber" className="block my-3 text-sm font-bold text-gray-200">
              {t("general:search_by")} {t("reportCentral:protocol_number")}
            </label>
            <div className="relative w-full mt-1 rounded-md shadow-sm">
              <CustomTextInput
                id="protocolNumber"
                {...register("protocolNumber", { 
                  validate: value => value.length === 14 || t('reportCentral:protocol_length_error'),
                  pattern: {
                    value: /^[0-9]+$/,
                    message: t('reportCentral:protocol_numeric_error')
                  }
                })}
                onKeyDown={onlyNumeric}
                autoFocus
                className="focus:ring-[#ff6160] focus:border-[#ff6160] block sm:text-sm border-0 rounded-md w-full bg-[#252529]"
                placeholder={t("reportCentral:protocol_number_placeholder")}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <CustomButton
              id="report-check-by-protocol-button"
              type="submit"
              content={t("general:search")}
              className="w-full px-4 py-2 font-bold text-[#eee] bg-[#ff6160] rounded-lg shadow hover:bg-[#ff4444] focus:outline-none focus:shadow-outline"
              loading={isSubmitting}
              disabled={isSubmitting}
            />
            <CustomButton
              id="back-to-beginning-button-for-report-check"
              type="button"
              content={t("general:back_to_beginning")}
              className="w-full px-4 py-2 font-bold text-[#eee] bg-[#ff6160] rounded-lg shadow hover:bg-[#ff4444] focus:outline-none focus:shadow-outline"
              onClick={() => navigate("/")}
            />
          </div>
        </center>
      </form>
      {showReportData && reportData && (
        <Modal
          id="report-check-modal"
          isOpen={isModalOpen}
          onClose={closeModal}
          title={t('general:search_by') + ' ' + t('reportCentral:protocol_number')}
        >
          <ReportDataDisplay data={reportData} isVisible={isModalOpen} />
        </Modal>
      )}
    </>
  )
}

export default ReportCheckForm
