// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warning-text {
  animation: blinker 1s linear infinite;
  color: rgb(165, 169, 44);
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/warningText.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;EACrC,wBAAwB;AAC1B;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".warning-text {\r\n  animation: blinker 1s linear infinite;\r\n  color: rgb(165, 169, 44);\r\n}\r\n\r\n@keyframes blinker {\r\n  50% {\r\n    opacity: 0;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
