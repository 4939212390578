import React from 'react'
import { WrapperProps } from '@generalInterfaces'

/**
 * MiniFormContainer Component.
 * 
 * @description
 * A container component for centralizing the login form on the page.
 * 
 * This component renders its children (typically form elements) inside
 * a styled div element, providing consistent layout and styling.
 * 
 * @param {MiniFormContainerProps} props - The props for the component.
 * @returns {React.ReactElement} A styled container component.
 */
const MiniFormContainer: React.FC<WrapperProps> = ({ children, className  }) => {
    const containerClasses = `${className || ''}`

  return (
    <div className="flex items-center justify-center h-80">
      <div className={containerClasses}>
        {children}
      </div>
    </div>
  )
}

export default MiniFormContainer
