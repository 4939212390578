import React from 'react'
import { CustomTextareaProps } from '@componentInterfaces'

/**
 * CustomTextarea Component
 * A custom textarea component for text input.
 *
 * @param {CustomTextareaProps} props - The props for the component.
 * @returns {React.ReactElement} - The CustomTextarea component.
 */
const CustomTextArea: React.FC<CustomTextareaProps> = ({ value, onChange, placeholder, id, ...rest }) => {
  return (
    <textarea
      id={id}
      value={value}
      placeholder={placeholder}
      className="focus:ring-[#ff6160] focus:border-[#ff6160] block sm:text-sm border-0 rounded-md w-full bg-[#1a1a1c] mt-2 min-h-40"
      onChange={onChange}
      {...rest}
    />
  )
}

export default CustomTextArea