import React from 'react'
import { DeviceIdentifierProvider, ProtectedRouteProvider } from '@generalProviders'
import { LoadingScreen } from '@generalComponents'
import { IntranetLayout } from 'domains/generalPanel/components'
import { privateUserRoutes, publicUserRoutes } from '@routes'
import { Routes, Route } from 'react-router-dom'
import { useLoading } from '@generalHooks'
import { NotFound } from '@errorPages'

/**
 * The main application router component.
 * It renders different routes based on the user's authentication status.
 * Private routes are wrapped with `AuthProtected` and `IntranetLayout` components.
 * Public routes are rendered directly.
 *
 * @returns {React.FC} - A functional component that renders the application router.
 */
const AppRouter: React.FC = () => {
    const { isLoading } = useLoading()

    return (
        <React.Fragment>
        <Routes>
            {privateUserRoutes.map((route: any, idx: number) => (
            <Route
                key={idx}
                path={route.path}
                element={
                <ProtectedRouteProvider id="route-provider-protected">
                    <IntranetLayout id="intranet-layout-wrapper" className='fade-in'>
                        <LoadingScreen isLoading={isLoading}>
                            <route.component />
                        </LoadingScreen>
                    </IntranetLayout>
                </ProtectedRouteProvider>
                }
            />
            ))}
            {publicUserRoutes.map((route: any, idx: number) => (
            <Route
                path={route.path}
                key={idx}
                element={
                <DeviceIdentifierProvider>
                    <LoadingScreen isLoading={isLoading}>
                        <route.component />
                    </LoadingScreen>
                </DeviceIdentifierProvider>
                }
            />
            ))}
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
        </React.Fragment>
    )
}

export default AppRouter
