import React from 'react'
import { ImageProps } from '@generalInterfaces'

/**
 * A functional component for rendering an image with additional attributes.
 *
 * @param props - The props for the Image component.
 * @param props.src - The source URL of the image.
 * @param props.alt - The alternative text for the image.
 * @param props.width - The width of the image.
 * @param props.height - The height of the image.
 * @param props.className - The CSS class name for the image.
 * @param props.loading - The loading behavior of the image. Default is 'lazy'.
 * @param props.onError - The event handler for the 'onerror' event.
 * @param props.onLoad - The event handler for the 'onload' event.
 *
 * @returns - A React functional component for rendering an image.
 */
const Image: React.FC<ImageProps> = ({
    id,
    src,
    alt,
    width,
    height,
    className,
    loading = 'lazy',
    onError,
    onLoad,
}) => {
    return (
        <img
            id={id}
            src={src}
            alt={alt}
            width={width}
            height={height}
            className={`fade-in ${className}`}
            loading={loading}
            onError={onError}
            onLoad={onLoad}
        />
    )
}

export default Image