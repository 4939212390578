import React from "react"
import { CSSTransition } from "react-transition-group"
import "@css/stepper.css"

/**
 * Stepper Component
 *
 * Represents a stepper component for navigating through multiple steps in a process.
 * It displays each step with a number or icon indicator and shows the current active step distinctly.
 * Transition effects are added between steps.
 *
 * @param {number} currentStep - The index of the current active step in the stepper.
 * @param {React.ReactNode[]} stepComponents - The array of React components for each step.
 * @param {string[]} stepLabels - Labels for each step.
 */

const Stepper: React.FC<{
  currentStep: number
  stepComponents: React.ReactNode[]
  stepLabels: string[]
}> = ({ currentStep, stepComponents, stepLabels }) => {
  return (
    <>
      <CSSTransition
        in={true}
        appear={true}
        timeout={300}
        classNames="step-transition"
        key={currentStep}
      >
        <div>
          <div className="visible fade-in">{stepComponents[currentStep - 1]}</div>
          <ol className="items-center visible w-full space-y-4 step-line-wrapper fade-in sm:flex sm:space-x-8 sm:space-y-0 rtl:space-x-reverse">
            {stepLabels.map((label, index) => (
              <li
                key={index}
                className={`step-line mx-2 flex items-center space-x-2.5 rtl:space-x-reverse ${currentStep === index + 1 ? "text-[#ff6160] dark:text-blue-500 mx-2" : "text-gray-500 dark:text-gray-400 mx-2"} step-topic`}
              >
                <span className={`p-1 flex items-center justify-center w-8 h-8 border rounded-full shrink-0 ${currentStep === index + 1 ? "border-[#ff6160] dark:border-blue-500" : "border-gray-500 dark:border-gray-400"}`}>
                  {index + 1}
                </span>
                <span className="max-w-[180px] md:max-w-none ">
                  <h3 className="font-medium leading-tight min-w-[100px] md:min-w-0 text-left step-sequency">{label}</h3>
                </span>
              </li>
            ))}
          </ol>
        </div>
      </CSSTransition>
    </>
  )
}

export default Stepper
