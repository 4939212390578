import InitialDashboardScreen from '../../generalPanel/generalDashboard/includes/InitialDashboardScreen'
import React from 'react'
import ReportCentral from '../../generalPanel/generalDashboard/reportCentral'
import { Home, Settings, Users } from 'lucide-react'
import { SidebarExtendedProps } from '@componentInterfaces'
import { useAppTranslation } from '@generalProviders'


/**
 * Sidebar Component
 *
 * @description
 * The Sidebar component renders the navigation sidebar for the dashboard. It includes links to 
 * various sections such as the InitialDashboardScreen and ReportCentral. This component uses 
 * icons from lucide-react and offers a responsive design that is hidden on smaller screens and 
 * becomes visible on medium and larger screens.
 * 
 * @param {SidebarExtendedProps} props - Properties passed to the component, including onSidebarClick 
 * for handling navigation and onReportSelect for report selection functionality.
 *
 * @returns {React.FC<SidebarExtendedProps>} The Sidebar functional component.
 *
 * @example
 * 
 * <Sidebar onSidebarClick={handleSidebarNavigation} />
 * 
 * @see 
 * InitialDashboardScreen, ReportCentral, Home, Settings, Users
 */
const Sidebar: React.FC<SidebarExtendedProps> = ({ onSidebarClick, onReportSelect }) => {
    const { t } = useAppTranslation()

    return (
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 bg-[#3b3b41] w-full border-r shadow-custom border-[#666]">
            <div className="flex flex-col flex-grow w-full pt-5 overflow-y-auto">
                <nav className="px-2 pb-4 space-y-1 block-1 sidebar-items">
                    <p 
                        className="flex items-center px-2 py-2 text-sm font-medium text-white rounded-md cursor-pointer group"
                        onClick={() => onSidebarClick(<InitialDashboardScreen />)}>
                        <Home className="w-6 h-6 mr-3" />
                        {t('general:home')}
                    </p>
                    <p 
                        className="flex items-center px-2 py-2 text-sm font-medium text-white rounded-md cursor-pointer group"
                        onClick={() => onSidebarClick(<ReportCentral />)}>
                        <Users className="w-6 h-6 mr-3" />
                        {t('reportCentral:report_central')}                    
                    </p>
                    <p className="flex items-center px-2 py-2 text-sm font-medium text-white rounded-md group">
                        <Settings className="w-6 h-6 mr-3" />
                        {t('general:settings')}
                    </p>
                </nav>
            </div>
        </div>
    )
}

export default Sidebar
