import React, { createContext, useContext, useEffect, useState } from 'react'
import { useAppTranslation } from './LanguageProvider'
import { v4 as uuidv4 } from 'uuid'

/**
 * Context for storing and retrieving a unique device identifier.
 */
const DeviceIdentifierContext = createContext<string | null>(null)

/**
 * Custom hook for accessing the device identifier within components.
 * @throws Will throw an error if used outside of DeviceIdentifierProvider.
 * @returns The unique device identifier.
 */
export const useDeviceIdentifier = () => {
  const { t } = useAppTranslation()
  const context = useContext(DeviceIdentifierContext)
  if (context === null) {
    throw new Error('useDeviceIdentifier ' + t('general:must_used_inside') +' DeviceIdentifierProvider')
  }
  return context
}

/**
 * Provides a unique device identifier to its child components. The identifier
 * is generated by combining a UUID, the current timestamp, and a random 10-digit number.
 * It is stored in the localStorage to persist across sessions.
 */
export const DeviceIdentifierProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [deviceIdentifier, setDeviceIdentifier] = useState<string | null>(null)

  useEffect(() => {
    const key = 'facss-DeviceIdentifier'
    const storedIdentifier = localStorage.getItem(key)

    if (storedIdentifier) {
      setDeviceIdentifier(storedIdentifier)
    } else {
      const timestamp = new Date().getTime()
      const randomDigits = Math.floor(Math.random() * 10000000000)
      const newIdentifier = `${uuidv4()}-${timestamp}-${randomDigits}`
      localStorage.setItem(key, newIdentifier)
      setDeviceIdentifier(newIdentifier)
    }
  }, [])

  return (
    <DeviceIdentifierContext.Provider value={deviceIdentifier}>
      {children}
    </DeviceIdentifierContext.Provider>
  )
}
