import React, { createContext, useContext, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { i18n as I18nType } from 'i18next'

type LanguageContextType = {
  t: (key: string) => string
  i18n: I18nType
}

/**
 * Creates a context for language translation.
 * This context allows child components to access the translation function and i18n instance.
 */
export const LanguageContext = createContext<LanguageContextType>({} as LanguageContextType)

/**
 * LanguageProvider Component.
 * This component provides the translation function and i18n instance to its children.
 * It uses the useTranslation hook from react-i18next to obtain these.
 * 
 * @param {ReactNode} children - Props containing children components.
 * @returns {React.ReactElement} - The LanguageProvider component wrapping its children.
 */
export const LanguageProvider: React.FC<{children: ReactNode}> = ({ children }) => {
  const { t, i18n } = useTranslation()

  return (
    <LanguageContext.Provider value={{ t, i18n }}>
      {children}
    </LanguageContext.Provider>
  )
}

/**
 * Custom hook for using the language translation context.
 * This hook provides an easy way to access the translation function and i18n instance.
 * 
 * @returns {LanguageContextType} - An object containing the translation function and i18n instance.
 */
export const useAppTranslation = () => useContext(LanguageContext)
