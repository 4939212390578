import React from 'react'
import backgroundImage from "@img/TruckBackground.png"

/**
 * IntroductionContainer is a React component that renders a responsive layout
 * with a main content area and a sidebar. The main content area, displayed
 * on medium and larger screens and hidden on small screens, has a minimum height
 * of 100% of the viewport. The sidebar takes full width on small screens and 20% width
 * on medium and larger screens. The background image covers the main content area
 * maintaining its aspect ratio and filling the width of the div.
 *
 * @param {React.ReactNode} children - The content to be displayed in the sidebar.
 * @returns {JSX.Element} The component's rendered elements.
 */
const IntroductionContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen md:flex-row fade-in">
      <div className="w-full bg-fixed bg-center bg-no-repeat bg-cover md:flex md:w-8/10 intro-image"
            style={{ 
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: '100% auto'
            }}>
        {/* Main content area */}
      </div>
      <aside className="w-full md:w-2/10">
        {children}
      </aside>
    </div>
  )
}

export default IntroductionContainer
