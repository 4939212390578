import React from 'react'
import { StepperNavigationButtonsProps } from '@componentInterfaces'

/**
 * StepperNavigationButtons is a React component that renders a set of two buttons for navigation purposes.
 * These buttons are typically used in a stepper or wizard-like interface.
 *
 * @param {StepperNavigationButtonsProps} props - Props including whether buttons are disabled, 
 * callback functions for Next and Back buttons, text for these buttons, and mouse event handlers.
 * @returns {React.ReactElement} - React element containing the two buttons.
 */
const StepperNavigationButtons: React.FC<StepperNavigationButtonsProps> = ({
  isDisabled,
  onNext,
  onBack,
  nextButtonText,
  backButtonText,
  onMouseEnter,
  onMouseLeave,
  firstButtonClassName,
  secondButtonClassName
}) => {
  return (
    <div className="flex justify-center mt-4 mb-5 space-x-4">
      <center>
        <button
          className={`block max-w-sm p-2 px-5 mx-2 mt-2 border border-[#ff6160] rounded-lg shadow btn-continue hover:text-white text-[#ff6160] ${isDisabled ? "" : "hover:bg-[#ff6160] cursor-pointer"} dark:bg-[#ff6160] dark:border-[#ff6160] dark:hover:bg-[#ff6160] ml-0 inline w-[210px] ${firstButtonClassName} step-button-responsive`}
          onClick={() => !isDisabled && onNext()}
          disabled={isDisabled}
          aria-disabled={isDisabled}
          type="button"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {nextButtonText}
        </button>
        <button
          className={`block max-w-sm p-2 px-5 mx-2 mt-2 border border-[#ff6160] rounded-lg shadow btn-continue hover:text-white text-[#ff6160] hover:bg-[#ff6160] cursor-pointer dark:bg-[#ff6160] dark:border-[#ff6160] dark:hover:bg-[#ff6160] inline w-[210px] mr-0 ${secondButtonClassName} step-button-responsive`}
          onClick={onBack}
          type="button"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {backButtonText}
        </button>
      </center>
    </div>
  )
}

export default StepperNavigationButtons
