import axios from 'axios'
import { Drawer } from '@generalComponents'
import Pagination from '../../../general/components/Pagination'
import React, { useEffect, useState, useCallback } from 'react'
import { SortArrow } from '../../../general/components/SortArrow'
import { Eye } from 'lucide-react'
import { formatDateOfString } from '../../../general/applicationManagement/utils/functions/DateFunctions'
import { Report, SortKey } from './interfaces/ReportDashboardInterfaces'
import { useAppTranslation } from '../../../general/providers/LanguageProvider'

/**
 * ReportCentral Component
 *
 * @description
 * The ReportCentral component displays a list of reports in a tabular format. It fetches
 * report data from the backend using Axios. Each row in the table provides details like report ID,
 * author, message, and other relevant information. Users can view detailed information about each report
 * by clicking on the 'eye' icon, which opens a drawer component on the screen.
 *
 * @returns {React.FC} The component renders a table with reports and a drawer for detailed views.
 *
 * @example
 * <ReportCentral />
 */
const ReportCentral: React.FC = () => {
  const { t } = useAppTranslation()
  const [reports, setReports] = useState<Report[]>([])
  const [selectedReport, setSelectedReport] = useState<Report | null>(null)
  const [sortConfig, setSortConfig] = useState<{ key: SortKey, direction: 'ascending' | 'descending' } | null>(null)
  const [page, setPage] = useState(1)
  const [paginationInfo, setPaginationInfo] = useState({
    currentPage: 1,
    totalPages: 1,
  })
  const limit = 10 // '10' limit-paginação melhorar isso aqui com configurações de usuário}

  /**
   * Fetches report data from the backend using Axios.
   *
   * @param {number} page - The current page number.
   */
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/report`, {
      params: { page,  limit } // '10' limit-paginação melhorar isso aqui com configurações de usuário}
    })
      .then(response => {
        setReports(response.data.data)
        setPaginationInfo({
          currentPage: response.data.meta.currentPage,
          totalPages: response.data.meta.lastPage
        })
      })
      .catch(error => {
      })
  }, [page, t])

  /**
   * Handles the selection of a report.
   * @param {Report} report - The report to be selected.
   */
  const handleSelectReport = useCallback((report: Report) => {
    setSelectedReport(report)
  }, [])

  /**
   * Handles the closing of the drawer.
   * Resets the selected report to null when the drawer is closed.
   */
  const handleCloseDrawer = useCallback(() => {
    setSelectedReport(null)
  }, [])

  /**
   * Requests a sort of the table based on the specified key of the Report type.
   * @param {keyof Report} key - The key of the Report type to sort by.
   */
  const requestSort = useCallback((key: SortKey) => {
    let direction: 'ascending' | 'descending' = 'ascending'
    if (sortConfig?.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending'
    }
    setSortConfig({ key, direction })
  }, [sortConfig])

  /**
   * Provides the sorted array of reports based on the current sort configuration.
   * @returns {Report[]} An array of sorted reports.
   */
  const sortedItems = () => {
    let sortableItems = [...reports]
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        let aValue = getNestedValue(a, sortConfig.key)
        let bValue = getNestedValue(b, sortConfig.key)
  
        if (sortConfig.key === 'evidencesCount') {
          aValue = a.evidences.length
          bValue = b.evidences.length
        }
  
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          aValue = aValue.toLowerCase()
          bValue = bValue.toLowerCase()
      } else {
          aValue = aValue || ''
          bValue = bValue || ''
      }
  
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1
        }
        return 0
      })
    }
    return sortableItems
  }
  
  /**
   * Gets the nested value from the given object and key.
   *
   * @param {any} obj - The object from which to retrieve the nested value.
   * @param {string} key - The key of the nested value.
   * @returns {any} The nested value.
   */
  function getNestedValue(obj: any, key: string) {
    return key.split('.').reduce((value, k) => {
      if (!value) return value
      return value[k]
    }, obj)
  }

  const goToPage = useCallback((pageNumber: number) => setPage(pageNumber), [])


  return (
    <center>
      <div className="report-central">
        <h1 className='dash-h1'>{ t('reportCentral:report_central')}</h1>
        <div className="table-container bg-[#323236] p-5 rounded-lg">
          <table className="block min-w-full border-collapse border-gray-900 md:table" style={{ width: '100%' }}>
            <thead className="block cursor-pointer md:table-header-group pointer">
              <tr className="block border border-transparent md:table-row">
                <th className="block p-3 text-center cursor-pointer md:table-cell" style={{ minWidth: '120px' }} onClick={() => requestSort('protocolNumber.protocolId')}>{t('reportCentral:protocol')} {sortConfig?.key === 'protocolNumber.protocolId' && <SortArrow direction={sortConfig.direction} />}</th>
                <th className="block p-3 text-center cursor-pointer md:table-cell" style={{ minWidth: '250px', textAlign: 'center' }} onClick={() => requestSort('author.authorType.name')}>{t('reportCentral:identification')} {sortConfig?.key === 'author.authorType.name' && <SortArrow direction={sortConfig.direction} />}</th>
                <th className="block p-3 text-center cursor-pointer md:table-cell" style={{ width: '500px', minWidth: '500px' }} onClick={() => requestSort('reportMessage')}>{t('reportCentral:report')} {sortConfig?.key === 'reportMessage' && <SortArrow direction={sortConfig.direction} />}</th>
                <th className="block p-3 text-center cursor-pointer md:table-cell" style={{ minWidth: '280px' }} onClick={() => requestSort('names')}>{t('reportCentral:envolved')} {sortConfig?.key === 'names' && <SortArrow direction={sortConfig.direction} />} </th>
                <th className="block p-3 text-center cursor-pointer md:table-cell" style={{ minWidth: '120px', textAlign: 'center' }} onClick={() => requestSort('status.name')}>{t('general:status')}  {sortConfig?.key === 'status.name' && <SortArrow direction={sortConfig.direction} />}</th>
                <th className="block p-3 text-center cursor-pointer md:table-cell" style={{ minWidth: '110px', textAlign: 'center' }} onClick={() => requestSort('evidencesCount')}>{t('general:evidences')} {sortConfig?.key === 'evidencesCount' && <SortArrow direction={sortConfig.direction} />} </th>
                <th className="block p-3 text-center cursor-pointer md:table-cell" style={{ minWidth: '130px' }} onClick={() => requestSort('assessment.name')} >{t('general:assessment')}  {sortConfig?.key === 'assessment.name' && <SortArrow direction={sortConfig.direction} />}</th>
                <th className="block p-3 text-center cursor-pointer md:table-cell" style={{ minWidth: '120px' }} onClick={() => requestSort('startDate')} >{t('reportCentral:event_date')}  {sortConfig?.key === 'startDate' && <SortArrow direction={sortConfig.direction} />}</th>
                <th className="block p-3 text-center cursor-pointer md:table-cell" style={{ minWidth: '140px' }} onClick={() => requestSort('created_at')}>{t('general:created_at')} {sortConfig?.key === 'created_at' && <SortArrow direction={sortConfig.direction} />}</th>                
                <th className="block p-3 text-center cursor-pointer md:table-cell" style={{ minWidth: '150px' }} onClick={() => requestSort('authorHashIdentifier')}>
                    {t('general:device')}  {sortConfig?.key === 'authorHashIdentifier' && <SortArrow direction={sortConfig.direction} />}
                </th>
                <th className="block p-3 text-center md:table-cell">{t('general:details')}</th>
              </tr>
            </thead>
            <tbody className="block md:table-row-group">
              {reports.length > 0 ? (
                sortedItems().map(report => (
                  <tr key={report.id} className="block border border-transparent border-gray-900 md:table-row">
                    <td className="block p-3 md:table-cell">{report.protocolNumber?.protocolId}</td>
                    <td className="block p-3 text-center md:table-cell">{report.author.authorType.name}</td>
                    <td className="block p-3 md:table-cell truncate-text-table">{report.reportMessage}</td>
                    <td className="block p-3 md:table-cell">{report.names}</td>
                    <td className="block p-3 text-center md:table-cell">{report.status.name}</td>
                    <td className="block p-3 text-center md:table-cell">{report.evidences.length}</td>
                    <td className="block p-3 text-center md:table-cell">{report.assessment.name}</td>
                    <td className="block p-3 text-center md:table-cell">{formatDateOfString(report.startDate)}</td>
                    <td className="block p-3 text-center md:table-cell">{formatDateOfString(report.createdAt)}</td>
                    <td className="block p-3 text-center md:table-cell">{report.author.hashIdentifyier}</td>
                    <td style={{ minWidth: '180px' }} onClick={() => handleSelectReport(report)}>
                      <center><Eye style={{ cursor: 'pointer' }}/></center>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="block border border-transparent border-gray-900 md:table-row">
                  <td colSpan={11} className="p-3 text-center">
                    <center>{t('reportCentral:no_report_found')}</center>
                  </td>
                </tr>
              )}
            </tbody>
          </table>  
          <Drawer report={selectedReport || undefined} isOpen={!!selectedReport} onClose={handleCloseDrawer} title={t('reportCentral:verify_report')}/>
        </div>
        <div className='items-center justify-center w-full mt-10 text-center'>
          <Pagination 
            currentPage={paginationInfo.currentPage}
            totalPages={paginationInfo.totalPages}
            onPageChange={goToPage}
          />
        </div>
      </div>
    </center>
  )
}

export default ReportCentral
