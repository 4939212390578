import { AnimatedSwitch } from '@generalUtils'
import { BrowserRouter as Router } from 'react-router-dom'
import { AppRouter } from '@routes'
import { 
    DomainAccessProvider,
    LanguageProvider,
    RedirectBasedOnDomain 
  } from '@generalProviders'
import { ToastContainer } from 'react-toastify'
import WebFont from 'webfontloader'
import '@css/app.css'
import '@locales'

WebFont.load({
  google: {
    families: ["Roboto:400,700", "Oxygen:400,700"],
  },
})

/**
 * App Component
 *
 * @description
 * The App component is the main component of the application. It initializes the loading state,
 * manages the display of the LoadingScreen component, and sets up the router with defined routes.
 * The routes include a programmatic redirect from the root path ('/') to '/report'.
 * The App uses AnimatedSwitch for animated transitions between routes.
 *
 * @returns {React.ReactElement} The App component with routing and animated transitions.
 */
function App() {

  return (
    <>
      <Router>
        <LanguageProvider>
          <DomainAccessProvider>
            <RedirectBasedOnDomain>
              <AnimatedSwitch>
                <AppRouter/>
              </AnimatedSwitch>
            </RedirectBasedOnDomain> 
          </DomainAccessProvider>
        </LanguageProvider>
      </Router>
      <ToastContainer />
    </>
  )
}

export default App
