import axios from 'axios'
import { showErrorToast } from '@generalUtilsFunctions'
import { useAppTranslation } from '@generalProviders'
import { useState, useCallback } from 'react'

/**
 * Custom hook for managing report channel related operations.
 *
 * @returns {Object} - An object containing methods for fetching data and getting styles.
 */
const useReportChannel = () => {
    const { t } = useAppTranslation()
    const [protocolData, setProtocolData] = useState<any>(null)
    const [showReportData, setShowReportData] = useState(false)

    /**
     * Fetches data from the backend based on the provided protocol number.
     *
     * @param {string} protocolNumber - The protocol number for the query.
     * @returns {Promise<any>} A promise resolving to the data fetched from the backend.
     */
    const fetchBackend = useCallback(async (protocolNumber: string): Promise<any> => {
        try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/report-protocol/${protocolNumber}`)
        return response.data
        } catch (error) {
        throw error
        }
    }, [])

    /**
     * Handles the submission of form data.
     *
     * Fetches data based on the submitted protocol number and updates state accordingly.
     *
     * @param {Object} data - The data extracted from the form.
     * @param {string} data.protocolNumber - The protocol number submitted in the form.
     * @returns {Promise<void>} A promise resolving when the submission is handled.
     */
    const onSubmit = useCallback(async (data: { protocolNumber: string }) => {
        try {
        const fetchedData = await fetchBackend(data.protocolNumber)
        setProtocolData(fetchedData)
        setShowReportData(true)
        } catch (error) {
        setShowReportData(false)
        if (axios.isAxiosError(error)) {
            if (error.response?.status === 404) {
            showErrorToast(t('reportCentral:protocol_not_found'))
            } else if (error.response?.status === 500) {
            showErrorToast(t('general:error_unexpected'))
            }
        }
        }
    }, [fetchBackend, t])

    /**
     * Gets the style for the status label based on the provided status.
     *
     * @param {string} status - The status of the report.
     * @returns {string} A string representing the CSS class for the status label.
     */
    const getStatusStyle = useCallback((status: string): string => {
        switch (status) {
        case 'Em Análise':
            return "text-xs tag-yellow"
        case 'Concluída':
            return "text-xs tag-green"
        default:
            return "text-xs tag-white"
        }
    }, [])

    /**
     * Gets the style for the assessment label based on the provided assessment.
     *
     * @param {string} assessment - The assessment of the report.
     * @returns {string} A string representing the CSS class for the assessment label.
     */
    const getAssessmentStyle = useCallback((assessment: string): string => {
        switch (assessment) {
        case 'Improcedente':
            return "text-xs tag-red"
        case 'Inconclusiva':
            return "text-xs tag-yellow"
        case 'Procedente':
            return "text-xs tag-green"
        default:
            return "text-xs tag-white"
        }
    }, [])

    return {
        protocolData,
        showReportData,
        fetchBackend,
        onSubmit,
        getStatusStyle,
        getAssessmentStyle,
    }
}

export default useReportChannel
