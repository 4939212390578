
import React from 'react'
import { CentralContainer, IntroductionContainer } from '@generalComponents'
import { ReportDecision } from '@reportCentral'

/**
 * ReportChoose Component.
 *
 * This component serves as the entry point for choosing the report process.
 * It wraps the ReportDecision component within the CentralContainer,
 * providing a consistent layout and style.
 *
 * The CentralContainer is used to align and format the content centrally,
 * ensuring a consistent user experience across different parts of the application.
 *
 * @returns {React.FC} The ReportChoose functional component.
 */
const ReportChoose: React.FC = () => {
  return (
    <IntroductionContainer>
      <CentralContainer>
        <ReportDecision />
      </CentralContainer>
    </IntroductionContainer>
  )
}

export default ReportChoose
