import axios from 'axios'
import { CreateReportRequestProps } from '@reportCentralJourneyInterfaces'
import { showErrorToast } from '@generalUtilsFunctions'
import { useAppTranslation } from '@generalProviders'
import { useForm } from 'react-hook-form'
import { useState, useCallback, useEffect } from 'react'

/**
 * Custom hook to manage the state and functionalities of StepTwo component.
 *
 * @param {number} authorId - The ID of the author.
 * @param {Function} onRequestNext - Function to be called when moving to the next step.
 * @param {Function} onBack - Function to be called when moving to the previous step.
 * @returns {Object} Object containing various state variables and handlers for the StepTwo component.
 */
export const useStepTwo = (authorId: number, onRequestNext: Function, onBack: Function) => {
  const { t } = useAppTranslation()
  const [startDate, setStartDate] = useState(new Date())
  const [evidences, setEvidences] = useState<File[]>([])
  const { register, handleSubmit, formState: { errors }, watch, control } = useForm<CreateReportRequestProps>()
  const [isLoading, setIsLoading] = useState(false)

  const allowedFileTypes = ['jpg', 'png', 'jpeg', 'pdf', 'mp3', 'mp4', 'webp']
  const maxFileSize = 250 * 1024 * 1024 
  const maxFileCount = 12

  const reportMessage = watch('reportMessage', '')

  /**
   * Handles the form submission for the StepTwo component.
   *
   * @param {CreateReportRequestProps} data - The form data object containing the names, reportMessage, and evidences.
   */
  const onSubmit = useCallback(async (data: CreateReportRequestProps) => {
    setIsLoading(true)
  
    const formData = new FormData()
    formData.append('authorId', String(authorId))
    formData.append('startDate', new Date(startDate).toISOString())
    formData.append('names', data.names || '') 
    formData.append('reportMessage', data.reportMessage || '') 
    evidences.forEach(file => formData.append('evidences', file))
    const deviceIdentifier = localStorage.getItem('facss-DeviceIdentifier')
    if (deviceIdentifier) {
      formData.append('deviceIdentifier', deviceIdentifier)
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/report`, 
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
  
      if (response.status === 201) {
        onRequestNext({ protocolNumber: response.data.protocolNumber })
      }
    } catch (error) {
      console.error(t('formValidation:form_submit_error'), error)
      showErrorToast(t('formValidation:form_submit_error'))
    } finally {
      setIsLoading(false)
    }
  }, [authorId, startDate, evidences, onRequestNext, t])

  useEffect(() => {
    if (errors.reportMessage) {
      showErrorToast(errors.reportMessage.message || t("general:uknown_error")) 
    }
  }, [errors.reportMessage, t])

  return {
    t, startDate, setStartDate, evidences, setEvidences, allowedFileTypes,
    maxFileSize, maxFileCount, register, handleSubmit, isLoading, onSubmit, onBack, reportMessage, control
  }
}
