import React from 'react'
import { CentralContainer, Stepper } from '@generalComponents'
import { ReportJourneyProvider, useReportJourneyContext } from '@reportCentralJourneyContexts'
import { StepOne, StepTwo, StepThree } from '@reportCentralComponents'
import { useAppTranslation } from '@generalProviders'
import '@locales'
import '@css/reportJourney.css'

/**
 * ReportJourney Component
 * 
 * Orchestrates the steps of the reporting journey.
 * Utilizes ReportJourneyFunctions for state and navigation logic management.
 * includes the Stepper component to navigate through the process steps.
 *
 * @returns {JSX.Element} The ReportJourney component.
 */
const ReportJourney: React.FC = (): JSX.Element => {

  return (
    <ReportJourneyProvider>
      <CentralContainer>
        <ReportJourneyContent />
      </CentralContainer>
    </ReportJourneyProvider>
  )
}

/**
 * ReportJourneyContent Component
 * 
 * This component includes the Stepper component to navigate through the process steps.
 * It is wrapped with the ReportJourneyProvider to provide context to its children.
 *
 * @returns {JSX.Element} The ReportJourneyContent component.
 */
const ReportJourneyContent: React.FC = (): JSX.Element => {
  const { currentStep } = useReportJourneyContext()
  const { t } = useAppTranslation()

  return (
    <Stepper
      currentStep={currentStep}
      stepComponents={[
        <StepOne key="step1" />,
        <StepTwo key="step2" />,
        <StepThree key="step3" />
      ]}
      stepLabels={[
        t('reportCentral:your_relation'),
        t('reportCentral:your_report'),
        t('reportCentral:your_protocol')
      ]}
    />
  )
}

export default ReportJourney
