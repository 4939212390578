import { 
  CentralContainer,
  IntroductionContainer,
  MiniFormContainer 
} from "@generalComponents"
import React from "react"
import ReportCheckForm from './components/forms/ReportCheckForm'
import { ReportChannelProvider } from './context/ReportChannelContext'

/**
 * ReportCheck Component.
 *
 * This component serves as the entry point for the report checking process. It leverages
 * the MiniFormContainer to provide a standardized and centered layout for the ReportCheckForm.
 * The ReportCheckForm is tasked with collecting and handling the protocol number input
 * from the user, which is then utilized to fetch and display report data.
 *
 * The MiniFormContainer ensures that the form is presented in a visually appealing manner,
 * maintaining consistency across the application.
 *
 * @returns {React.ReactElement} The ReportCheck functional component, rendering the form for report checking.
 */
const ReportCheck: React.FC = () => {


  return (
    <IntroductionContainer>
      <CentralContainer>
        <MiniFormContainer 
          className="max-w-[300px] w-full p-6 m-4 rounded-lg shadow-lg bg-[#312c2c] my-30"
          id="report-check-form-container"
        >
          <ReportChannelProvider>
            <ReportCheckForm />
          </ReportChannelProvider>  
        </MiniFormContainer>
      </CentralContainer>
    </IntroductionContainer>
  )
}

export default ReportCheck
