

/**
 * Prevents non-alphanumeric characters from being entered.
 * Allows only letters and numbers. Blocks all other characters except Backspace and Tab.
 * 
 * @param event - The keyboard event captured from the user input.
 */
export const onlyAlphaNumeric = (event: any) => {
  if (!/^[A-Za-z0-9]+$/.test(event.key) && event.key !== "Backspace" && event.key !== "Tab") {
    event.preventDefault()
  }
}

/**
 * Prevents non-numeric characters from being entered.
 * Allows only numbers. Blocks all other characters except Backspace, Tab, and Ctrl+V for paste.
 * 
 * @param event - The keyboard event captured from the user input.
 */
export const onlyNumeric = (event: any) => {
  if (event.ctrlKey && event.key === 'v') {
    return
  }
  if (!/^[0-9]+$/.test(event.key) && event.key !== "Backspace" && event.key !== "Tab") {
    event.preventDefault()
  }
}
