import React, { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

interface AnimatedSwitchProps {
    children: ReactNode
}

/**
 * AnimatedSwitch Component
 * 
 * @description
 * The AnimatedSwitch component uses 'react-transition-group' to apply animations to route transitions.
 * It listens for changes in the route location using 'useLocation' hook from 'react-router-dom' and
 * applies a CSS transition to animate the switching between different routes.
 * 
 * @param {React.ReactNode} children - The child components, typically Routes defined in 'react-router-dom'.
 */
const AnimatedSwitch: React.FC<AnimatedSwitchProps> = ({ children }) => {
    const location = useLocation()

    return (
        <TransitionGroup>
            <CSSTransition
                key={location.pathname}
                classNames="fade"
                timeout={300}
            >
                {children}
            </CSSTransition>
        </TransitionGroup>
    )
}

export default AnimatedSwitch
