import React, { useState } from 'react'
import { Download } from 'lucide-react'
import { Evidence } from '@componentInterfaces'
import '@css/gallery.css'

const Gallery = ({ evidences }: { evidences: Evidence[] }) => {
  const [selectedImage, setSelectedImage] = useState<Evidence | null>(null)

  const handleImageClick = (evidence: Evidence) => {
    setSelectedImage(evidence)
  }

  const handleCloseImage = () => {
    setSelectedImage(null)
  }

  return (
    <div className="gallery">
      {evidences?.map((evidence: Evidence, index: number) => (
        <div key={evidence.id} className="gallery-item">
          {evidence.path.endsWith('.jpg') || evidence.path.endsWith('.jpeg') || evidence.path.endsWith('.png') || evidence.path.endsWith('.webp') ? (
            <div>
              <img
                src={`${process.env.REACT_APP_FILE_SERVE_URL}${evidence.path}`}
                alt={`Evidência ${index + 1}`}
                onClick={() => handleImageClick(evidence)}
                className="gallery-image fade-in"
              />
              <p className="evidence-text">Arquivo {index + 1}</p>
            </div>
          ) : (
            <div>
              <a href={`${process.env.REACT_APP_FILE_SERVE_URL}${evidence.path}`} download className="gallery-download" target='_blank' rel="noreferrer">
                <Download style={{ width: 60}}/>

                Download
              </a>
              <p className="evidence-text">Arquivo {index + 1}</p>
            </div>
          )}
        </div>
      ))}
      {selectedImage && (
        <div className="lightbox" onClick={handleCloseImage}>
          <img
            src={`${process.env.REACT_APP_FILE_SERVE_URL}${selectedImage?.path}`}
            alt={selectedImage?.path}
            className="lightbox-image"
          />
          <button onClick={handleCloseImage} className="lightbox-close">
            Fechar
          </button>
        </div>
      )}
    </div>
  )
}

export default Gallery
