import React from 'react'
import { CustomButton, CustomPasswordInput } from '@generalComponents'
import { useAppTranslation } from '@generalProviders'
import { useChangePassword } from 'domains/generalPanel/hooks'
import '@locales'

/**
 * ChangePassword Component
 *
 * This component allows the user to change their password upon first access.
 *
 * @returns {React.ReactElement} The rendered ChangePassword component.
 */
const ChangePassword: React.FC = () => {
  const { register, handleSubmit, watch, loading } = useChangePassword()
  const { t } = useAppTranslation()

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900" id="general">
      <div className="w-full max-w-md p-6 m-4 bg-gray-700 rounded-lg shadow-lg">
        <h2 className="mb-4 text-2xl font-bold text-center text-gray-200">
          {t('general:change_pass_title')}
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="newPassword" className="block mb-2 text-sm font-bold text-gray-200 align-left justify-left">
              {t('general:type_new_password')}
            </label>
            <CustomPasswordInput
              id="newPassword"
              autoFocus
              {...register('newPassword', {
                required: t('formValidation:password_required'),
                minLength: { value: 8, message: t('formValidation:change_pass_min_length') },
                maxLength: { value: 16, message: t('formValidation:change_pass_max_length') },
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/,
                  message: t('formValidation:change_pass_pattern')
                },
                validate: value => (value !== 'mudar@123' && value !== 'Mudar@123') || t('formValidation:change_pass_common')
              })}
              autoComplete="new-password"
            />
          </div>
          <div className="mb-6">
            <label htmlFor="confirmPassword" className="block mb-2 text-sm font-bold text-gray-200 align-left justify-left">
              {t('general:type_confirmed_password')}            
            </label>
            <CustomPasswordInput
              id="confirmPassword"
              {...register('confirmPassword', {
                required: t('formValidation:confirm_password_required'),
                validate: value => value === watch('newPassword') || t('formValidation:change_pass_match')
              })}
              autoComplete="new-password"
            />
          </div>
          <div className="flex items-center justify-between">
            <CustomButton
              id="chage-password-button"
              type="submit"
              content={t('general:change_pass')}
              className="w-full px-4 py-2 font-bold text-[#eee] bg-[#ff6160] rounded-lg shadow hover:bg-[#ff4444] focus:outline-none focus:shadow-outline"
              loading={loading}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default ChangePassword
