import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
/**
 * Displays an informational toast message.
 * 
 * @param message - The message to be displayed in the toast.
 */
export const showInfoToast = (message: string) => {
    toast.info(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
  
  /**
   * Displays an informational toast message.
   * 
   * @param message - The message to be displayed in the toast.
   */
  export const showSuccessToast = (message: string) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
  
  /**
   * Displays an error toast message.
   * 
   * @param message - The message to be displayed in the toast.
   */
  export const showErrorToast = (message: string) => {
    toast.error(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
  
  
  /**
   * Displays an warn toast message.
   * 
   * @param message - The message to be displayed in the toast.
   */
  export const showWarnToast = (message: string) => {
    toast.warn(message, {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
  
