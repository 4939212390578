// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stepper-content {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.stepper-content.fade-in {
  opacity: 1;
}

.step-transition-enter {
  opacity: 0;
  transform: translateX(-20px);
}

.step-transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition:
    opacity 300ms,
    transform 300ms;
}

.step-transition-exit {
  opacity: 1;
  transform: translateX(0);
}

.step-transition-exit-active {
  opacity: 0;
  transform: translateX(20px);
  transition:
    opacity 300ms,
    transform 300ms;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/stepper.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,qCAAqC;AACvC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,4BAA4B;AAC9B;;AAEA;EACE,UAAU;EACV,wBAAwB;EACxB;;mBAEiB;AACnB;;AAEA;EACE,UAAU;EACV,wBAAwB;AAC1B;;AAEA;EACE,UAAU;EACV,2BAA2B;EAC3B;;mBAEiB;AACnB","sourcesContent":[".stepper-content {\r\n  opacity: 0;\r\n  transition: opacity 300ms ease-in-out;\r\n}\r\n\r\n.stepper-content.fade-in {\r\n  opacity: 1;\r\n}\r\n\r\n.step-transition-enter {\r\n  opacity: 0;\r\n  transform: translateX(-20px);\r\n}\r\n\r\n.step-transition-enter-active {\r\n  opacity: 1;\r\n  transform: translateX(0);\r\n  transition:\r\n    opacity 300ms,\r\n    transform 300ms;\r\n}\r\n\r\n.step-transition-exit {\r\n  opacity: 1;\r\n  transform: translateX(0);\r\n}\r\n\r\n.step-transition-exit-active {\r\n  opacity: 0;\r\n  transform: translateX(20px);\r\n  transition:\r\n    opacity 300ms,\r\n    transform 300ms;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
