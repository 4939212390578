import React, { createContext, useState, useContext, ReactNode } from 'react'
import { GeneralDashboardContextType } from './interfaces/GeneralDashboardInterfaces'

/**
 * Context for managing the state of the current child component in GeneralDashboard.
 */
const GeneralDashboardContext = createContext<GeneralDashboardContextType>({} as GeneralDashboardContextType)

/**
 * Custom hook to use the GeneralDashboard context.
 * @returns {GeneralDashboardContextType} The context value.
 */
export const useGeneralDashboard = () => useContext(GeneralDashboardContext)

/**
 * Provider component for GeneralDashboardContext.
 * @param {ReactNode} children - The child components that will consume the context.
 * @returns {React.FC<{children: ReactNode}>} A React Functional Component.
 */
export const GeneralDashboardProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [currentChild, setCurrentChild] = useState<ReactNode>(null)

  return (
    <GeneralDashboardContext.Provider value={{ currentChild, setCurrentChild }}>
      {children}
    </GeneralDashboardContext.Provider>
  )
}
