import { useState, useEffect, useContext, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { showErrorToast, onlyNumeric } from '@generalUtilsFunctions'
import { useAppTranslation } from '@generalProviders'
import { ReportChannelContext } from '@reportCentralChannelContexts'

/**
 * Custom hook to handle the logic of the ReportCheckForm component.
 *
 * @returns {Object} - Returns an object containing necessary methods and state for the form.
 */
const useReportCheckForm = () => {
    const { t } = useAppTranslation()
    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({ mode: 'onSubmit' })
    const navigate = useNavigate()
    const { fetchReport, reportData, showReportData } = useContext(ReportChannelContext)
    const [isModalOpen, setIsModalOpen] = useState(false)

    useEffect(() => {
        const errorMessage = errors.protocolNumber?.message
        if (typeof errorMessage === 'string') {
        showErrorToast(errorMessage)
        }
    }, [errors.protocolNumber, t])

    const onSubmit = useCallback(async (data: any) => {
        await fetchReport(data.protocolNumber)
        setIsModalOpen(true)
    }, [fetchReport])

    const closeModal = useCallback(() => {
        setIsModalOpen(false)
    }, [])

    return {
        t,
        register,
        handleSubmit,
        onSubmit,
        isSubmitting,
        navigate,
        onlyNumeric,
        showReportData,
        reportData,
        isModalOpen,
        closeModal
    }
}

export default useReportCheckForm
