import React from 'react'
import { AuthorTypeOptionProps } from '@reportCentralJourneyInterfaces'
import { Image } from '@generalComponents'
import { useAppTranslation } from '@generalProviders'
/**
 * AuthorTypeOption Component
 *
 * This component renders an individual author type option.
 * Users can select one of these options to define their relationship with Lenarge.
 *
 * @param {AuthorTypeOptionProps} props - The properties for the AuthorTypeOption component.
 */

const AuthorTypeOption: React.FC<AuthorTypeOptionProps> = ({
  type,
  index,
  isSelected,
  onSelect,
}) => {
  const { t } = useAppTranslation()
  return (
    <div
      className={`author-type-option ${isSelected ? "is-selected-author " : ""}`}
      onClick={() => onSelect(index + 1)}
    >
    <div className={`fade-in visible flex items-center max-w-xs p-0 mx-auto mt-2 border border-gray-200 rounded-lg shadow option px- dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 from-white  hover:text-gray-900 text-gray-900 ${isSelected ? "bg-[#ff6160]" : "bg-gradient-to-r from-95% hover:bg-gray-100"}`}>
      <Image
        id={`report-journey-author-${index + 1}`}
        className="fade-in visible object-cover w-[50px] md:w-20 rounded-t-lg h-66 md:h-auto md:rounded-none md:rounded-s-lg fade-in visible button-image"
        src={`./img/report-channel/${index + 1}.webp`}
        alt={type}
      />
        <div className="flex flex-col justify-end justify-between w-full p-1 leading-normal">
          <h5 className="w-full pr-3 text-sm font-bold tracking-tight text-right author-type-text">
            { index + 1 !== 5 && t('general:i_am')} {type} { t('general:lenarge')}
          </h5>
        </div>
      </div>
    </div>
  )
}

export default AuthorTypeOption
