import axios from 'axios'
import { showErrorToast, showSuccessToast } from '@generalUtilsFunctions'
import { SubmitHandler, FieldErrors } from 'react-hook-form'
import { LoginFormInputs } from "@componentInterfaces"
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { useAppTranslation } from '@generalProviders'
import { handleValidationErrors } from '@generalUtils'

/**
 * Custom hook for handling the form submission and error handling logic for the login form.
 *
 * @returns {object} An object containing the onSubmit and showError functions.
 */
export const useLoginFormHandlers = () => {
  const { t } = useAppTranslation()
  const navigate = useNavigate()

  /**
   * Handles the form submission. Upon successful submission of a valid protocol number,
   * it fetches the corresponding data from the backend.
   *
   * @param data - The form data containing the email and password.
   * @returns {Promise<void>}
   */
  const onSubmit: SubmitHandler<LoginFormInputs> = useCallback(async (data) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/login`, data)
      
      if (response.data.data && response.data.data.user_token.token) {
        const token = response.data.data.user_token.token
        localStorage.setItem('lng-bkfc-token', token)
        
        if (data.password === 'mudar@123') {
          navigate('/mudar-senha', { state: { email: data.email } })
        } else {
          showSuccessToast(t('general:login_success'))
          navigate('/painel')
        }
      } else {
        throw new Error('Token not found in response')
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        showErrorToast(t('general:login_error') + ": " + error.response.data.message)
      } else {
        showErrorToast(t('general:error_unexpected'))
      }
    }
  }, [navigate, t])

  return { onSubmit, showError: (errors: FieldErrors<LoginFormInputs>) => handleValidationErrors(errors, t) }
}
