import React from 'react'
import { CustomButtonProps } from '@componentInterfaces'
import { Spinner } from 'flowbite-react'

/**
 * A custom React functional component that renders a button with custom styles and props.
 *
 * @remarks
 * This component is designed to be used in React applications to create buttons with consistent styling.
 * It accepts all standard HTML button attributes and additional props through the `CustomButtonProps` interface.
 *
 * @param props - The props for the CustomButton component.
 * @param props.content - The content to be displayed inside the button.
 * @param props.rest - Additional props to be spread onto the button element.
 *
 * @returns - A React functional component that renders a button with custom styles and props.
 *
 * @example
 * ```tsx
 * import React from 'react';
 * import CustomButton from './CustomButton';
 *
 * const App: React.FC = () => {
 *   return (
 *     <div>
 *       <CustomButton onClick={() => console.log('Button clicked!')} content="Click me" />
 *     </div>
 *   );
 * };
 *
 * export default App;
 * ```
 */
const CustomButton: React.FC<CustomButtonProps> = ({ content, loading, className, ...props }) => {
    return (
        <button
            {...props}
            className={`w-full px-4 py-2 font-bold text-[#eee] bg-[#ff6160] rounded-lg shadow hover:bg-[#ff4444] focus:outline-none focus:shadow-outline ${className}`}
        >
            {loading ? <Spinner /> : content}
        </button>
    )
}

export default CustomButton
