import React, { useState, useEffect } from 'react'
import { CustomFileInputProps } from '@componentInterfaces'
import { WarningText } from '@generalUtils'
import { useAppTranslation } from '@generalProviders'
import { useCustomFileInput } from '@componentHooks'

/**
 * CustomFileInput Component
 * Renders a file input control with drag-and-drop support.
 * It handles file validations for size, type, duplicate names, and limits the number of files.
 * Also, it displays uploaded files and provides a way to delete them.
 *
 * @param {CustomFileInputProps} props - Props including setEvidences, evidences, allowedFileTypes, maxFileSize, and maxFileCount.
 * @returns {React.ReactElement} - The CustomFileInput component.
 */
const CustomFileInput: React.FC<CustomFileInputProps> = ({
    setEvidences, evidences, allowedFileTypes, maxFileSize, maxFileCount
}) => {
  const { t } = useAppTranslation()
  const {
    isDragOver, notification, fileInputRef, notificationRef,
    handleFileInputChange, handleDrop, handleDragOver, handleDragLeave,
    handleDeleteFile, triggerFileInput
  } = useCustomFileInput(setEvidences, evidences, allowedFileTypes, maxFileSize, maxFileCount)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div>
      <div
        className={`cursor-pointer block p-5 mt-2 border-2 ${isDragOver ? "border-[#ff6160] bg-white text-[#ff6160]" : "border-gray-300 bg-[#1a1a1c] text-gray"} border-dashed rounded-lg shadow w-full`}
        onClick={triggerFileInput}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        {isMobile ? t('general:drag_drop_evidence_mobile') : t('general:drag_drop_evidence')}
        <input
          ref={fileInputRef}
          type="file"
          multiple
          className="hidden"
          onChange={handleFileInputChange}
          accept={allowedFileTypes.map((type) => `.${type}`).join(', ')}
        />
      </div>
      <ul className="mt-4">
        {evidences.length > 0 && <li>{t('reportCentral:your_evidences')}</li>}
        {evidences.map((file, index) => (
          <li  
              key={index}         
              className="flex items-center justify-between p-2 border-b"
              onClick={(e) => e.stopPropagation()}
          >
            <span className='justify-start text-left truncate-text items-left justify-left align-left'>
              {file.name}
            </span>
              <span 
                  onClick={(e) => {
                    e.stopPropagation() 
                    handleDeleteFile(index) 
                  }}
                  className="text-white text-red-500 cursor-pointer"
              >
                  X
              </span>
          </li>
        ))}
      </ul>
      {notification ? (
        <div ref={notificationRef} className="my-2 notification">
          <WarningText text={notification} />
        </div>
      ) : (
        <div ref={notificationRef} className="my-2 notification">
          { t('general:evidences_above') }
        </div>
      )}
    </div>
  )
}

export default CustomFileInput
