import { FieldErrors } from 'react-hook-form'
import { showErrorToast } from '@generalUtilsFunctions'

/**
 * Handle form validation errors and show corresponding toast messages.
 *
 * @param errors - The validation errors from the form.
 * @param t - The translation function.
 */
export const handleValidationErrors = (errors: FieldErrors<any>, t: (key: string) => string) => {
  Object.values(errors).forEach(error => {
    if (error?.message && typeof error.message === 'string') {
      showErrorToast(t('general:error_simple') + ": " + t(error.message))
    }
  })
}
