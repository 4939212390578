import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

/**
 * Custom hook to handle authentication.
 *
 * This hook checks if a token is stored in the local storage. If no token is found, it redirects the user to the login page.
 *
 * @returns {void} No return value.
 */
const useAuth = () => {
  /**
   * React's hook to navigate between routes.
   */
  const navigate = useNavigate()

  /**
   * React's hook to perform side effects in function components.
   */
  useEffect(() => {
    /**
     * Retrieves the token from the local storage.
     */
    const token = localStorage.getItem('lng-bkfc-token')

    /**
     * Checks if the token exists.
     */
    if (!token) {
      /**
       * Redirects the user to the login page.
       */
      navigate('/login')
    }
  }, [navigate])
}

export default useAuth