import React from "react"
import { 
  CentralContainer,
  IntroductionContainer,
  LoginForm,
  MiniFormContainer
 } from "@generalComponents"

/**
 * BackOfficeInitialScreen Component
 *
 * @description 
 * The BackOfficeInitialScreen component serves as the main interface for HR personnel to check reports.
 * It utilizes the MiniFormContainer to provide a consistent and visually appealing layout
 * for the LoginForm, which is responsible for accepting and handling the input of a report's 
 * protocol number. The protocol number is used to fetch and display relevant report data.
 * 
 * The MiniFormContainer ensures that the form is centered and maintains a uniform appearance 
 * across the application.
 *
 * @returns {React.FC} 
 * The BackOfficeInitialScreen functional component, which renders the form for checking reports.
 *
 * @example
 * 
 * <BackOfficeInitialScreen />
 *
 * @note 
 * Make sure to provide the necessary API endpoint for report fetching in the LoginForm component.
 *
 * @see 
 * LoginForm, IntroductionContainer, CentralContainer
 */
const BackOfficeInitialScreen: React.FC = () => {
  return (
    <IntroductionContainer>
      <CentralContainer>
        <MiniFormContainer 
          className="max-w-[300px] w-full p-6 m-4 rounded-lg shadow-lg bg-[#312c2c]"
          id="itranet-login-form-container"
        >
          <LoginForm />
        </MiniFormContainer>
      </CentralContainer>
    </IntroductionContainer>
  )
}

export default BackOfficeInitialScreen
