import React from 'react'
import { PaginationProps } from '@componentInterfaces'
import { useAppTranslation } from '@generalProviders'

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const { t } = useAppTranslation()

  return (
    <div className="flex justify-center">
      <nav aria-label={t('general:navigation')} className="text-center">
        <ul className="flex items-center h-8 -space-x-px text-sm">
          <li>
            <div onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
                 className={`flex items-center justify-center px-3 h-8 leading-tight text-white bg-[#323236] border border-black rounded-s-lg hover:bg-gray-100 hover:text-gray-700 cursor-pointer ${currentPage <= 1 ? 'opacity-50 cursor-not-allowed' : ''}`}>
              {t('general:previous')}
            </div>
          </li>
          {Array.from({ length: totalPages }, (_, i) => (
            <li key={i}>
              <div onClick={() => onPageChange(i + 1)}
                   className={`flex items-center justify-center px-3 h-8 leading-tight text-white bg-[#323236] border border-black hover:bg-gray-100 hover:text-gray-700 cursor-pointer ${currentPage === i + 1 ? 'text-white border-gray-600 bg-gray-600' : ''}`}>
                {i + 1}
              </div>
            </li>
          ))}
          <li>
            <div onClick={() => currentPage < totalPages && onPageChange(currentPage + 1)}
                 className={`flex items-center justify-center px-3 h-8 leading-tight text-white bg-[#323236] border border-black rounded-e-lg hover:bg-gray-100 hover:text-gray-700 cursor-pointer ${currentPage >= totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}>
              {t('general:next')}
            </div>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Pagination
