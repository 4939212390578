import { IntranetLayoutProps } from '@generalInterfaces'
import React from 'react'



/**
 * A functional component representing the layout for the intranet application.
 * This component wraps the children components and provides a common layout structure.
 *
 * @param props - The props for the IntranetLayout component.
 * @param props.children - The child components to be rendered within the layout.
 *
 * @returns - A React.FC component that renders the intranet layout.
 */
const IntranetLayout: React.FC<IntranetLayoutProps> = ({ children }) => {
    return (
        <React.Fragment>
        {children}
        </React.Fragment>
    )
}

export default IntranetLayout