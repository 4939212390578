import React, { useState, forwardRef } from 'react'
import { CustomInputProps } from '@componentInterfaces'
import { EyeIcon, EyeOffIcon } from 'lucide-react'

/**
 * CustomPasswordInput Component
 *
 * A custom input component for password input with an eye icon to toggle visibility.
 *
 * @param {CustomInputProps} props - The properties of the component.
 * @returns {React.ReactElement} - The CustomPasswordInput component.
 */
const CustomPasswordInput = forwardRef<HTMLInputElement, CustomInputProps>(({ id, value, onChange, placeholder, autoComplete, ...rest }, ref) => {
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className="relative w-full">
      <input
        type={showPassword ? "text" : "password"}
        id={id}
        value={value}
        placeholder={placeholder}
        className="focus:ring-[#ff6160] focus:border-[#ff6160] block sm:text-sm border-0 rounded-md w-full bg-[#1a1a1c] mb-2 mt-2 custom-input"
        onChange={onChange}
        autoComplete={autoComplete}
        ref={ref}
        {...rest}
      />
      <div
        className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
        onClick={togglePasswordVisibility}
      >
        {showPassword ? (
          <EyeOffIcon className="w-5 h-5 text-gray-500" />
        ) : (
          <EyeIcon className="w-5 h-5 text-gray-500" />
        )}
      </div>
    </div>
  )
})

export default CustomPasswordInput
