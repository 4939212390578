import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { ProtectedRouteProviderProps } from '@generalInterfaces'

/**
 * A React functional component that provides protected routing functionality.
 * It checks if a user is authenticated by verifying the existence of a token in local storage.
 * If the token is not present, it redirects the user to the login page.
 *
 * @param props - The properties passed to the component.
 * @param props.children - The child components to be rendered within the protected route.
 *
 * @returns - A React functional component that handles protected routing.
 */
const ProtectedRouteProvider: React.FC<ProtectedRouteProviderProps> = ({ children }) => {
  /**
   * The current location object provided by the 'react-router-dom' library.
   */
  const location = useLocation()

  /**
   * Retrieves the token from local storage.
   */
  const token = localStorage.getItem('lng-bkfc-token')

  /**
   * Checks if the token is present.
   * If the token is not present and the current location is not the login page,
   * it redirects the user to the login page.
   */
  if (!token) {
    if (location.pathname !== '/login') {
      return <Navigate to="/login" state={{ from: location }} replace />
    }
  } 

  /**
   * Renders the child components if the user is authenticated.
   */
  return <>{children}</>
}

export default ProtectedRouteProvider