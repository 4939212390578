import React from 'react'
import { CopyToClipboardButton } from '@generalUtils'
import { StepperNavigationButtons } from '@generalComponents'
import { useAppTranslation } from '@generalProviders'
import { useNavigate } from 'react-router-dom'
import { useReportJourneyContext } from '@reportCentralJourneyContexts'

/**
 * StepThree Component
 * 
 * This component displays the protocol number and provides options to navigate or copy the number.
 *
 * @returns {JSX.Element} The StepThree component.
 */
const StepThree: React.FC = (): JSX.Element => {
  const { t } = useAppTranslation()
  const navigate = useNavigate()
  const { goBackToStepTwo, protocolNumber } = useReportJourneyContext()

  return (
    <div className="w-full text-center fade-in">
      <div className="w-full text-xl text-warn">
        <h2>{t('reportCentral:your_protocol_number')}</h2>
      </div>
      <div className="flex justify-center w-11/12 mx-auto my-4 space-x-4 md:w-full">
        {protocolNumber && (
          <div className="flex items-center justify-between bg-[#ff6160] p-4 rounded-lg text-white">
            <div
              id="protocol-number"
              className="text-4xl font-semibold font-bold text-center text-white md:text-6xl"
            >
              {protocolNumber}
            </div>
            <CopyToClipboardButton 
              targetId="protocol-number" 
              id='copy-protocol-number'
              idSufix="copy-button"
            />
          </div>
        )}
      </div>
      <div className="w-full">
        <p>
          {t('reportCentral:step_three_1st_paragraph')}
        </p>
      </div>
      <div className="flex justify-center w-full my-4 space-x-4">
        <StepperNavigationButtons
          isDisabled={false}
          onNext={() => navigate("/acompanhar-denuncia")}
          onBack={goBackToStepTwo}
          nextButtonText={t('reportCentral:check_report')}
          backButtonText={t('general:back')}
          onMouseEnter={() => {}}  
          onMouseLeave={() => {}}  
        />
      </div>
    </div>
  )
}

export default StepThree
