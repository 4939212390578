import checkReportImage from '@img/report-central/6b.webp'
import reportImage from '../../assets/img/report-central/5b.webp'
import { ImageButton, Logo, Modal } from '@generalComponents'
import { LGPDContent } from '@reportCentralComponents'
import { useAppTranslation } from '@generalProviders'
import { useState } from 'react'

/**
 * ReportDecision Component.
 *
 * This component represents the first step in the report process.
 * It provides two options: to either track an existing report or create a new report.
 * Selection leads the user to the respective route or the next process step.
 *
 * @returns {React.FC} A functional component rendering the decision step with two options.
 */
const ReportDecision = () => {
  const { t } = useAppTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)



  return (
    <>
      <div className="text-center">
        <Logo
          id="report-decision-logo"
        />
        <h1 className="m-2 text-lg">{t('reportCentral:report_central_title')}</h1>
        <p className="mt-4 text-sm">
          {t('reportCentral:report_central_introduction_1st_paragraph')}
          <span className="text-emphasis">{t('reportCentral:protocol_number')}</span>
          {t('general:for_checking')}
          {t('reportCentral:report_central_introduction_2nd_paragraph')}
          <span className="text-emphasis">
            {t('reportCentral:secret_and_anonimously_warranty')}
          </span>
          {t('reportCentral:report_central_introduction_3rd_paragraph')}
        </p>
        <p 
          onClick={() => setIsModalOpen(true)} 
            className='my-5 pointer text-[#ff6160] hover:text-[#ccc] text-sm cursor-pointer' 
            id="report-central-open-lgpd-modal"
          >
          {t('lgpd:report_central_data_advice')}
        </p>
        <p className="mt-5 mb-4">
          <i>{t('general:select_below')}</i>
        </p>
        <ImageButton
          id="report-central-make-report"
          imageId='report-central-make-report-button-image'
          navigateTo="/fazer-denuncia"
          buttonText={t('reportCentral:make_report')}
          imageSrc={checkReportImage}
        />
        <ImageButton
          id="report-central-check-report"
          imageId='report-central-check-report-button-image'
          navigateTo="/acompanhar-denuncia"
          buttonText={t('reportCentral:check_report')}
          imageSrc={reportImage}
        />
      </div>
      <Modal
        id="report-decision-modal"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={t('lgpd:how_we_guard_dada') }
        >
          <LGPDContent />
        </Modal>
    </>
  )
}

export default ReportDecision
