import React from 'react'
import { Image }  from '@generalComponents'
import { useAppTranslation } from '@generalProviders'
import notFoundImage from '@img/general/404.webp'

/**
 * NotFound Component
 *
 * @returns {React.ReactElement} The rendered NotFound component.
 */
const NotFound: React.FC = (): React.ReactElement => {
  const { t } = useAppTranslation()

  return (
    <div className="flex items-center justify-center min-h-screen text-gray-800 bg-gray-900" id='general'>
      <div className="text-center">
        <Image
          id="error-404"
          className='fade-in'
          src={notFoundImage}
        />
        <br />
        <br />
        <h1 className="mb-4 text-4xl font-bold text-white">Erro 404</h1>
        <p className="mb-4 text-xl text-white">{t('general:page_not_found')}</p>
        <a href="/" className="text-[#ff6160] hover:underline">
          {t('general:go_home_screen')}
        </a>
      </div>
    </div>
  )
}

export default NotFound
